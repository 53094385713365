<template>
  <ui-prop-value-selector :block="block" name="lottie" title="Lottie animation" only-var-type="lottie,string" :parent-diagram-id="diagram_id"/>
  <ui-prop-checkbox title="Loop" name="loop" :block="block"/>
  <ui-prop-string name="width" title="Width" :block="block" default-value="100%"/>
  <ui-prop-string name="height" title="Height" :block="block" default-value="100%"/>

  <ui-prop-select :block="block" name="fitting" title="Lottie fitting" :options="globals.options.mediaFitting" default-value="contain"/>
  <ui-prop-select :block="block" name="position" title="Lottie position" :options="globals.options.mediaPosition" default-value="center"/>

  <ui-dynamic-props :default-value="{}" :block="block" />
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';

export default {
  mixins: [propertiesMixins],
  name: "LottieAnimationPropsCmp",
  props: ['block'],
  components: {UiPropSelect, UiPropValueSelector, UiPropString, UiPropCheckbox, UiDynamicProps}
}

</script>

