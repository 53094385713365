<template>

  <ui-prop-section :title="title" :hint="hint" class="ui-data-props">

    <q-tabs v-if="isOnlyDataTab || isOnlySchemaTab" v-model="tab">
      <q-tab name="data" label="Data"/>
      <q-tab name="schema" label="Schema"/>
    </q-tabs>

    <q-tab-panels v-model="tab">

      <q-tab-panel name="schema">

        <div v-for="(item, k) of currentItems.fields" :key="k" class="q-pa-sm row">
          <div class="col row q-gutter-sm">
            <q-input
                class="col"
                v-model="item.name"
                label="Item name"
            />
            <q-select
                class="col"
                v-model="item.type"
                label="Item type"
                map-options
                emit-value
                :options="globals.options.data_types"
            />
          </div>
          <q-btn icon="delete" size="sm" flat round @click="deleteField(k)"/>
        </div>

        <q-btn @click="addField" icon="add" label="Add field" flat size="sm"/>

      </q-tab-panel>

      <q-tab-panel name="data">

        <div v-for="(item, k) of currentItems.items" :key="`${item.id}-${k}`" :class="'q-pa-sm row ' + (k % 2 === 1 ? 'bg-grey-2' : '')">

          <div class="col q-gutter-y-md">
            <div v-for="(fld, fk) of currentItems.fields" :key="'f-'+fk">
              <template v-if="typeof fld.displayAllow !== 'function' || fld.displayAllow(item)">
                <template v-if="fld.type === 'string'">
                  <q-input class="col" v-model="item[fld.name]" :label="fld.title || fld.name"/>
                </template>

                <template v-else-if="fld.type === 'localizable'">
                  <dynamic-string
                    v-model="item[fld.name]"
                    :app-id="product_id"
                    :module-id="module_id"
                    :block-id="block.id"
                    :parent-diagram-id="diagram_id"
                    :title="fld.title || fld.name"
                  >
                    <template #actions>
                      <q-checkbox
                        class="q-ml-sm"
                        v-model="item[fld.name].isLocalizable"
                        :false-value="false"
                        true-value="plain"
                        color="positive"
                        checked-icon="public"
                        unchecked-icon="public_off"
                        title="Translatable"
                      />

                      <localization-editor
                        v-if="item[fld.name].isLocalizable"
                        v-model="item[fld.name].value"
                        :alias="item[fld.name].localeAlias"
                      />
                    </template>
                  </dynamic-string>
                </template>

                <template v-else-if="fld.type === 'select'">
                  <q-select class="col" v-model="item[fld.name]" map-options emit-value :label="fld.title || fld.name"
                            :options="fld.options"/>
                </template>

                <template v-else-if="['image', 'sound', 'subtitles'].includes(fld.type)">
                  <media-picker
                    :media-type="fld.type"
                    v-model="item[fld.name]"
                    :label="fld.title || fld.name"
                    :product-id="product_id"
                    :module-id="module_id"
                  />
                </template>

                <template v-else-if="fld.type === 'icon'">
                  <icon-selector v-model="item[fld.name]" label="Menu item icon"/>
                </template>

                <template v-else-if="fld.type === 'variable'">
                  <value-selector
                    v-model="item[fld.name]"
                    :app-id="product_id" :module-id="module_id"
                    :title="fld.title || fld.name"
                    :parent-diagram-id="diagram_id" :block-id="block.id"/>
                </template>

                <template v-else-if="fld.type === 'color'">
                  <q-select v-model="item[fld.name]" emit-value map-options option-value="value" label="Color"
                            :options="moduleColors"/>
                </template>
              </template>

            </div>
          </div>

          <div class="column">
            <q-btn
              :disable="k === 0"
              icon="expand_less"
              size="sm"
              flat
              round
              @click="moveUp(k)"
            />

            <q-btn
              :disable="k === currentItems.items.length - 1"
              icon="expand_more"
              size="sm"
              flat
              round
              @click="moveDown(k)"
            />

            <q-btn class="q-mt-lg" icon="delete" size="sm" flat round @click="deleteItem(k)"/>
          </div>
        </div>

        <q-btn @click="addItem" icon="add" label="Add item" flat size="sm"/>

      </q-tab-panel>
    </q-tab-panels>

  </ui-prop-section>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import MediaPicker from "@/components/MediaGallery/MediaPicker";
import IconSelector from "@/components/IconSelector/IconSelector.vue";
import {nanoid} from "nanoid";
import LocalizationEditor from '@/components/Localizations/LocalizationEditor.vue';
import DynamicString from '@/components/DynamicString/DynamicString.vue';
import UiPropSection from "@/components/DiagramDesigner/Editor/properties/UiPropSection.vue";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";

export default {
  mixins: [propertiesMixins],
  inject: {
    canvas: {}
  },
  props: {
    block: {},
    defaultValue: {
      type: Array,
    },
    defaultSchema: {
      type: Array,
      default: () => [],
    }
  },
  name: "UiDataProps",
  components: {ValueSelector, UiPropSection, DynamicString, LocalizationEditor, IconSelector, MediaPicker},
  data: () => ({
    tab: "data",
    currentItems: false
  }),
  created() {

    // Init fields
    if (!this.currentValue || !this.currentValue.fields && !this.currentValue.items) this.currentValue = {
      fields: this.defaultSchema,
      items: this.defaultValue
    }

    // Set default schema if set
    if (this.isDefaultSchema) this.currentValue.fields = this.defaultSchema

    // Init value
    this.currentItems = this.currentValue

    // Set schema by default
    if (!this.currentItems.fields.length) {
      this.tab = "schema"
    }

    // Set localizable fields
    const localizableFields = (this.currentItems?.fields || []).filter((fld) => fld.type === 'localizable')
      .map((fld) => fld.name);

    // Patch items
    if ((this.currentItems?.items || []).length && localizableFields.length) {
      // Add localizable fields to the item
      this.currentItems.items = this.currentItems.items.map((item) => {
        for (const fld of localizableFields) {
          // If not set
          if (!item[fld] || typeof item[fld] !== 'object') {
            item[fld] = {
              value: item[fld] || '',
              isLocalizable: false,
              localeAlias: nanoid(10),
            }
          }
        }

        return item;
      });
    }

    // Add watcher
    this.$watch('currentItems', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})

  },

  methods: {

    /**
     * Add item
     * @param e
     */
    addItem: function () {
      // Add to items list
      if (!this.currentItems.items) this.currentItems.items = [];

      // Create new item
      const item = {id: nanoid(10)};

      // Add localizable fields to the item
      this.currentItems.fields.filter((fld) => fld.type === 'localizable').forEach((fld) => {
        item[fld.name] = {
          value: "",
          isLocalizable: false,
          localeAlias: nanoid(10),
        }
      });

      // Add to items list
      this.currentItems.items.push(item)
    },

    /**
     * Add item
     * @param e
     */
    addField() {

      // Add to items list
      this.currentItems.fields.push({
        name: "field",
        type: "string"
      })
    },

    /**
     * Delete item
     * @param k
     */
    deleteItem(k) {
      this.currentItems.items.splice(k, 1);
    },

    /**
     * Delete inner item
     * @param k
     */
    deleteInnerItem(index, name, innerIndex) {
      this.currentItems.items[index][name].splice(innerIndex, 1);
    },

    deleteSubInnerItem(index, name, innerIndex, subPropName, subInnerIndex) {
      this.currentItems.items[index][name][innerIndex][subPropName].splice(subInnerIndex, 1);
    },

    /**
     * Delete field
     * @param k
     */
    deleteField(k) {
      this.currentItems.fields.splice(k, 1);
    },

    /**
     * Move an item from one position to another in the currentItems list.
     *
     * @param {number} sourceIndex - the index of the item to be moved
     * @param {number} destinationIndex - the index where the item should be moved to
     * @return {void}
     */
    moveItem(sourceIndex, destinationIndex) {
      // Validate
      if (
        sourceIndex < 0
        || sourceIndex >= this.currentItems.items.length
        || destinationIndex < 0
        || destinationIndex >= this.currentItems.items.length
      ) {
        console.error("Invalid index");
        return;
      }

      const element = this.currentItems.items[sourceIndex];

      this.currentItems.items.splice(sourceIndex, 1);
      this.currentItems.items.splice(destinationIndex, 0, element);
    },

    /**
     * Move the item up in the list.
     *
     * @param {number} itemIndex - the index of the item to be moved up
     * @return {void}
     */
    moveUp(itemIndex) {
      this.moveItem(itemIndex, itemIndex - 1);
    },

    /**
     * Move the item down in the list.
     *
     * @param {number} itemIndex - the index of the item to be moved down
     * @return {void}
     */
    moveDown(itemIndex) {
      this.moveItem(itemIndex, itemIndex + 1);
    },
  },

  computed: {
    isDefaultSchema() {
      return !!this.defaultSchema.length;
    },
    isDataFields() {
      return !!this.currentValue.fields.length;
    },
    isOnlyDataTab() {
      return this.isDataFields && !this.isDefaultSchema;
    },
    isOnlySchemaTab() {
      return !this.isDataFields && this.isDefaultSchema;
    },
  }
}

</script>

<style lang="scss" scoped>
.ui-data-props {
  .q-tab {
    margin-bottom: 0;
  }

  .q-tab-panels {
    padding: 0;
  }

  .q-tab-panel {
    padding: 0;
  }
}

</style>
