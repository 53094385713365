<template>

  <modal-dialog ref="newComponent" title="New component" persistent>
    <component-form :edit-component="newComponent" @saved="componentAdded"/>
  </modal-dialog>

  <ui-prop-string name="title" title="Component title" :block="block"/>

  <template v-if="moduleType !== 'server'">
    <ui-prop-checkbox name="isEmbedded" title="Embedded diagram" :block="block"/>
    <ui-prop-checkbox name="isModalDialog" title="Modal dialog" :block="block"/>
  </template>

  <ui-modal-props v-if="block?.properties?.isModalDialog === 1" :block="block" />

  <div class="row q-gutter-x-sm">
    <ui-prop-select class="col" :block="block" name="diagramComponentId" title="Diagram component" :options="componentsList"/>
    <q-btn v-if="block.properties?.diagramComponentId" icon="edit" flat class="q-mt-md bg-blue-1" size="sm" @click="editComponent"></q-btn>
    <q-btn icon="add" flat class="q-mt-md bg-blue-1" size="sm" @click="addComponent"></q-btn>
  </div>

  <ui-prop-arguments v-if="targetDiagramId" :block="block" name="arguments" title="Diagram arguments"
                     :target-block-id="block.id" :diagram-only="true"/>

</template>

<script>

import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import {Diagram} from "@/../../common/db/Diagram.js"
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import {nanoid} from "nanoid";
import UiPropArguments from "@/components/DiagramDesigner/Editor/properties/UiPropArguments.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiModalProps from '@/components/DiagramDesigner/Editor/properties/UiModalProps.vue';
import ModalDialog from "@/components/ModalDialog/ModalDialog.vue";
import ComponentForm from "@/pages/workspace/diagrams/components/ComponentForm.vue";

export default {
  inject: ['canvas', 'product_id', 'module_id', 'currentModule'],
  components: {ComponentForm, ModalDialog, UiModalProps, UiPropCheckbox, UiPropArguments, UiPropString, UiPropSelect},
  props: ['block'],
  name: "DiagramComponentPropsCmp",
  data: () => ({
    tab: "props",
    newComponent:{}
  }),

  computed: {

    /**
     * Module type
     * @return {*}
     */
    moduleType() {
      return this.currentModule.currentModule?.type;
    },

    /**
     * Return diagram id
     * @return {Document.diagramComponentId}
     */
    targetDiagramId() {
      return this.block.properties?.diagramComponentId;
    },

    // Return all components from the store
    componentsList() {
      return this.wait("components", async () => {
        return (await Diagram.query().where("app_id", this.canvas.product_id).where("module_id", this.module_id).where("status", "active").get()).filter(e => e.id !== this.diagram_id).map(el => ({
          label: el.title,
          value: el.id
        }))
      }, [])
    },
  },

  methods: {

    /**
     * Component added
     * @param cmp
     */
    componentAdded(cmp) {
      // Set new component
      this.mutate(this.block).properties.diagramComponentId = cmp.id;

      // Hide dialog
      this.$refs.newComponent.hide()
    },

    /**
     * Add new component
     */
    addComponent() {
      // Set new component
      this.newComponent = {
        app_id: this.product_id,
        module_id: this.module_id,
        unique_id: nanoid(10),
        diagram_type: 'component',
        version: 0,
        status: 'active',
      }

      // Open dialog window
      this.$refs.newComponent.show()
    },

    /**
     * Edit component
     */
    async editComponent() {

      // Load component
      this.newComponent = await Diagram.find(this.block.properties.diagramComponentId)

      // Open dialog window
      this.$refs.newComponent.show()
    }
  }
}

</script>
