<template>
  <q-dialog ref="dialog" :persistent="persistent">
    <q-card class="full-width" :style="{maxWidth}">
      <q-card-section class="row bg-primary text-white">
        <div class="text-h6">{{title}}</div>
        <q-space/>
        <q-btn flat dense round @click="hide" icon="close" aria-label="Close"/>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <slot></slot>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>

export default {
  name: "ModalDialog",
  props: {
    title: {
      type: String,
    },
    persistent: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: undefined
    },
  },
  methods: {
    show() {
      this.$refs.dialog.show()
    },
    hide() {
      this.$refs.dialog.hide()
    }
  }
}

</script>
