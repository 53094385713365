<template>
  <q-splitter v-if="isReady"
              class="col storage-tree"
              :horizontal="horizontal"
              v-model="splitterModel"
  >

    <template v-slot:before>

      <q-tree v-if="nodes.length" ref="tree"
              :nodes="nodes"
              node-key="id"
              label-key="type"
              v-model:expanded="expandedNodes"
              v-model:selected="selectedNodeId"
              no-selection-unset
      >
        <template v-slot:default-header="prop">
          <div :draggable="!readonly" class="node-title" :class="{'drop-over': prop.node.id === dragOver}" @dragover.prevent @dragenter="dragOver = prop.node.id" @dragleave="dragOver = false" @drop="onDrop($event, prop.node)" @dragstart.stop="startDrag($event, prop.node)">{{ prop.node.name + (prop.node.title ? (`: ${prop.node.title}`) : '') }}</div>
          <q-space/>
          <q-btn v-if="!readonly" icon="menu" flat size="sm">
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item clickable v-close-popup @click="addNode(prop.node.parent_id)">
                  <q-item-section>Add node</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="addNode(prop.node.id)" v-if="['object', 'db-record'].includes(prop.node.type)">
                  <q-item-section>Add property</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="duplicateNode(prop.node)">
                  <q-item-section>Duplicate node</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="deleteNode(prop.node)">
                  <q-item-section class="text-red">Delete node</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </template>
      </q-tree>

      <q-btn v-if="!readonly" class="q-ma-sm" @click="addNode(0)" label="Add variable" color="primary"></q-btn>

    </template>

    <template v-slot:after>
      <storage-node-value :mode="mode" :key="selectedNodeId" :node-id="selectedNodeId" @add-node="addNode" :module-id="moduleId" :readonly="readonly"/>
    </template>

  </q-splitter>

</template>

<style>
</style>

<script>
import {StorageNode} from "@/../../common/db/StorageNode.js"
import StorageNodeValue from "@/pages/workspace/storage/StorageNodeValue.vue";
import {nanoid} from "nanoid";

export default {
  name: 'StorageTree',
  components: {StorageNodeValue},
  nodes: {},
  props: {
    appId: {},
    mode: {
      default: "storage"
    },
    moduleId: {},
    block_id: {
      default: "app-storage"
    },
    horizontal: {
      default: false
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    onlyArguments: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    splitterModel: 30,
    dragOver: false,
    editNode: false,
    isReady: false,
    expandedNodes: [],
    selectedNodeId: false
  }),

  /**
   * Init setting first
   */
  async created() {
    await this.initSettings()
    this.isReady = true
  },

  computed: {

    /**
     * Return all storage nodes
     * @returns {*}
     */
    nodes() {
      return this.wait("nodes", async () => {

        // Get app nodes, it is tree structure id, parent_id
        const nodes = await StorageNode.query()
          .where({
            app_id: this.appId,
            module_id: this.moduleId,
            block_id: this.block_id,
            ...(this.onlyArguments ? { is_argument: 1 } : {})
          })
          .get()

        // Store nodes recursively as tree with key, label, children
        const tree = (nodes, parent_id) => nodes.filter(node => node.parent_id === parent_id).map(node => ({
          id: node.id,
          title: node.title,
          type: node.type,
          name: node.name,
          parent_id: node.parent_id,
          children: tree(nodes, node.id)
        }))

        // Return tree
        return tree(nodes, 0)

      }, [])
    }
  },

  methods: {

    /**
     * Start drag
     * @param event
     * @param node
     */
    startDrag(event, node) {
      event.dataTransfer.setData("text/plain", JSON.stringify(node));
      return true;
    },

    /**
     * On drop
     * @param event
     * @param node
     */
    async onDrop(event, node) {

      // Move node
      const source = JSON.parse(event.dataTransfer.getData("text/plain"));
      const targetId = node?.parent_id

      // Check if target is child of source
      if(source.parent_id === targetId) return false;

      // Get all parents of target
      const parents = (await (await StorageNode.find(targetId))?.parents()) || []

      // Check if source is not a parent
      if(parents.find(p => p.id === source.id)) return false;

      // Move node
      await StorageNode.remote().save({
        id: source.id,
        parent_id: targetId,
        deleted: 0
      })

      // Log
      this.dragOver = false;
      return true;
    },

    /**
     * Init default settings
     */
    async initSettings() {

      // Only for block app-storage - global application settings
      if (this.block_id !== "app-storage") return;

      // Structure to be created
      const settingsStructure = {
        children: [
          {
            name: "sound",
            title: "Sound management",
            type: "object",
            children: [
              {name: "musicEnabled", title: "Music enabled", type: 'bool', value: 'true'},
              {name: "soundEnabled", title: "Sound enabled", type: 'bool', value: 'true'},
              {name: "vibrationEnabled", title: "Vibration enabled", type: 'bool', value: 'true'},
            ]
          },
          {
            name: "statistics",
            title: "App statistics",
            type: "object",
            children: [
              {name: "firstSessionDate", title: "Date of first install", type: 'datetime', value: 0},
            ]
          },
          {
            name: "auth",
            title: "Current user",
            type: "object",
            children: [
              {name: "id", title: "Current user Id", type: 'integer', value: 0},
              {name: "roles", title: "Current user roles", is_array: true, type: 'string', value: []},
            ]
          },
          {
            name: "platform",
            title: "Platform settings",
            type: "object",
            children: [
              {name: "os", title: "Device OS", type: 'string', value: 'n/a'},
            ]
          },
          {
            name: "application",
            title: "Application data",
            type: "object",
            children: [
              {name: "version", title: "Version", type: 'string', value: 'n/a'},
              {name: "isKeyboardOpen", title: "The keyboard is open", type: 'bool', value: 'false'},
            ]
          },
        ]
      }

      // Get app nodes, it is tree structure id, parent_id
      let parentNode = await StorageNode.query().where({module_id: this.moduleId, block_id: this.block_id, parent_id: 0, name: "settings"}).first()

      // Create new if not exists
      if (!parentNode) parentNode = await StorageNode.remote().save({
        name: "settings",
        type: "object",
        app_id: this.appId,
        module_id: this.moduleId,
        block_id: this.block_id,
        parent_id: 0,
        unique_id: nanoid(10),
        title: "Application settings"
      })

      // Create structure
      const createStruct = async (parent, node) => {

        // Create node if not exists
        let newNode = !node.name ? parent : await StorageNode.query().where({parent_id: parent.id, name: node.name}).first()
        if(!newNode) newNode = await parent.addChild(node.name, node.title, node.type, node.value);

        // Trying to load current node children
        for(const ch of node.children || []) {
          await createStruct(newNode, ch)
        }
      }

      // Create structure
      await createStruct(parentNode, settingsStructure)
    },

    /**
     * Add node
     * @param node
     * @param parent_id
     * @return {Promise<void>}
     */
    async addNode(parent_id = 0) {
      this.$q.dialog({
        title: 'Add node',
        message: 'Enter node name',
        cancel: true,
        persistent: true,
        prompt: {
          model: '',
          type: 'text'
        }
      }).onOk(async (data) => {
        await StorageNode.remote().save({
          name: data,
          app_id: this.appId,
          module_id: this.moduleId,
          block_id: this.block_id,
          type: "string",
          parent_id: parent_id,
          unique_id: nanoid(10)
        })
      })
    },

    /**
     * Duplicate node
     * @param node
     * @return {Promise<void>}
     */
    async duplicateNode(node) {

      // Load node details
      const nd = await StorageNode.find(node.id)

      // Remove id
      nd.id = undefined
      nd.unique_id = nanoid(10);

      // Save node
      await nd.remote().save()
    },

    /**
     * Delete node
     * @param node
     * @return {Promise<void>}
     */
    async deleteNode(node) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${node.name} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        StorageNode.remote().delete(node.id)
      })
    },
  }
}
</script>

<style lang="scss">

.storage-tree {
  .node-title {
    width: 100%;
  }
  .drop-over {
    border-bottom: 2px solid #00f;
  }
}


</style>
