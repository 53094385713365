import FragmentEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Containers/Fragment/FragmentEditorCmp";
import FragmentPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Containers/Fragment/FragmentPropsCmp";
import TextEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Text/TextEditorCmp.vue";
import TextPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Text/TextPropsCmp.vue";
import ImageEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Image/ImageEditorCmp.vue";
import ImagePropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Image/ImagePropsCmp.vue";
import ContainerEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Containers/Container/ContainerEditorCmp.vue";
import ContainerPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Containers/Container/ContainerPropsCmp.vue";
import RepeaterEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Data/Repeater/RepeaterEditorCmp.vue";
import RepeaterPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Data/Repeater/RepeaterPropsCmp.vue";
import CodeFunctionEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/CodeFunction/CodeFunctionEditorCmp.vue";
import CodeFunctionPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/CodeFunction/CodeFunctionPropsCmp.vue";
import DiagramComponentEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Containers/DiagramComponent/DiagramComponentEditorCmp.vue";
import DiagramComponentPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Containers/DiagramComponent/DiagramComponentPropsCmp.vue";
import TeleportEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Containers/Teleport/TeleportEditorCmp.vue";
import TeleportPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Containers/Teleport/TeleportPropsCmp.vue";
import CustomEventEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Navigation/CustomEvent/CustomEventEditorCmp.vue";
import CustomEventPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Navigation/CustomEvent/CustomEventPropsCmp.vue";
import FABEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/FAB/FABEditorCmp.vue";
import FABPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/FAB/FABPropsCmp.vue";
import FormEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Form/Form/FormEditorCmp.vue";
import FormPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Form/Form/FormPropsCmp.vue";
import InputEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Form/Input/InputEditorCmp.vue";
import InputPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Form/Input/InputPropsCmp.vue";
import SubmitEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Form/Submit/SubmitEditorCmp.vue";
import SubmitPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Form/Submit/SubmitPropsCmp.vue";
import ButtonEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Button/ButtonEditorCmp.vue";
import ButtonPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Button/ButtonPropsCmp.vue";
import DialogEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Navigation/Dialog/DialogEditorCmp.vue";
import DialogPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Navigation/Dialog/DialogPropsCmp.vue";
import VideoEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Video/VideoEditorCmp.vue";
import VideoPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Video/VideoPropsCmp.vue";
import LottieAnimationEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Basic/LottieAnimation/LottieAnimationEditorCmp.vue";
import LottieAnimationPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Basic/LottieAnimation/LottieAnimationPropsCmp.vue";
import BannerEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Monetization/Banner/BannerEditorCmp.vue";
import BannerPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Monetization/Banner/BannerPropsCmp.vue";
import RichTextEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/RichText/RichTextEditorCmp.vue";
import RichTextPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/RichText/RichTextPropsCmp.vue";
import ChildrenContainerEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Containers/ChildrenContainer/ChildrenContainerEditorCmp.vue";
import SeparatorEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Basic/Separator/SeparatorEditorCmp.vue";
import SeparatorPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Separator/SeparatorPropsCmp.vue";
import CheckPermissionEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Device/Permissions/Check/CheckPermissionEditorCmp.vue";
import CheckPermissionPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Device/Permissions/Check/CheckPermissionPropsCmp.vue";
import RequestPermissionEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Device/Permissions/Request/RequestPermissionEditorCmp.vue";
import RequestPermissionPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Device/Permissions/Request/RequestPermissionPropsCmp.vue";
import DeviceNavigatorEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Device/Navigator/DeviceNavigatorEditorCmp.vue";
import DeviceNavigatorPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Device/Navigator/DeviceNavigatorPropsCmp.vue";
import CheckBoxEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Form/CheckBox/CheckBoxEditorCmp.vue";
import CheckBoxPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Form/CheckBox/CheckBoxPropsCmp.vue";
import SelectEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Form/Select/SelectEditorCmp.vue";
import SelectPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Form/Select/SelectPropsCmp.vue";
import SliderEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Form/Slider/SliderEditorCmp.vue";
import SliderPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Form/Slider/SliderPropsCmp.vue";
import DatePickerEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Form/DatePicker/DatePickerEditorCmp.vue";
import DatePickerPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Form/DatePicker/DatePickerPropsCmp.vue";
import ChildrenContainerPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Containers/ChildrenContainer/ChildrenContainerPropsCmp.vue";
import LinkPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Containers/Link/LinkPropsCmp.vue";
import NativeComponentPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/NativeComponent/NativeComponentPropsCmp.vue";
import NativeComponentEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/NativeComponent/NativeComponentEditorCmp.vue";
import FullscreenAdEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/FullscreenAd/FullscreenAdEditorCmp.vue";
import FullscreenAdPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/FullscreenAd/FullscreenAdPropsCmp.vue";
import LogicConditionPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/LogicCondition/LogicConditionPropsCmp.vue";
import LogicConditionEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/LogicCondition/LogicConditionEditorCmp.vue";
import DelayEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Logic/Timeout/DelayEditorCmp.vue";
import DelayPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Logic/Timeout/DelayPropsCmp.vue";
import PlaySoundEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Sound/PlaySound/PlaySoundEditorCmp.vue";
import PlaySoundPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Sound/PlaySound/PlaySoundPropsCmp.vue";
import SoundManagerEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Sound/SoundManager/SoundManagerEditorCmp.vue";
import SoundManagerPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Sound/SoundManager/SoundManagerPropsCmp.vue";
import VibrateEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Sound/Vibrate/VibrateEditorCmp.vue";
import VibratePropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Sound/Vibrate/VibratePropsCmp.vue";
import IconEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Icon/IconEditorCmp.vue";
import IconPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Icon/IconPropsCmp.vue";
import NavigationPanelPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Navigation/NavigationPanel/NavigationPanelPropsCmp.vue";
import NavigationPanelEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Navigation/NavigationPanel/NavigationPanelEditorCmp.vue";
import AppHeaderEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Navigation/Toolbar/AppHeaderEditorCmp.vue";
import AppHeaderPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Navigation/Toolbar/AppHeaderPropsCmp.vue";
import TreeViewEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Data/TreeView/TreeViewEditorCmp.vue";
import TreeViewPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Data/TreeView/TreeViewPropsCmp.vue";
import DiagramPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Diagram/DiagramPropsCmp.vue";
import DbSaveEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/DB/DbSave/DbSaveEditorCmp.vue";
import DbSavePropsCmp from "@/components/DiagramDesigner/Editor/components/UI/DB/DbSave/DbSavePropsCmp.vue";
import DbQueryEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/DB/DbSelect/DbQueryEditorCmp.vue";
import DbQueryPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/DB/DbSelect/DbQueryPropsCmp.vue";
import DbDeleteEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/DB/DbDelete/DbDeleteEditorCmp.vue";
import DbDeletePropsCmp from "@/components/DiagramDesigner/Editor/components/UI/DB/DbDelete/DbDeletePropsCmp.vue";
import {
    DiagramComponentProcessor
} from "@/components/DiagramDesigner/Editor/components/UI/Containers/DiagramComponent/DiagramComponentProcessor";
import {DbDeleteProcessor} from "@/components/DiagramDesigner/Editor/components/UI/DB/DbDelete/DbDeleteProcessor";
import {
    FragmentProcessor
} from "@/components/DiagramDesigner/Editor/components/UI/Containers/Fragment/FragmentProcessor";
import {
    CodeFunctionProcessor
} from "@/components/DiagramDesigner/Editor/components/UI/Logic/CodeFunction/CodeFunctionProcessor";
import LogoutEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Auth/LogoutEditorCmp.vue";
import CheckRolePropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Auth/CheckRole/CheckRolePropsCmp.vue";
import CheckRoleEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Auth/CheckRole/CheckRoleEditorCmp.vue";
import SignUpEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Auth/SignUp/SignUpEditorCmp.vue";
import SignInEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Auth/Signin/SignInEditorCmp.vue";
import SignInPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Auth/Signin/SignInPropsCmp.vue";
import CarouselEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Containers/Carousel/CarouselEditorCmp.vue";
import CarouselPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Containers/Carousel/CarouselPropsCmp.vue";
import FakeLogicEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/FakeLogic/FakeLogicEditorCmp.vue";
import FakeLogicPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Logic/FakeLogic/FakeLogicPropsCmp.vue";
import InAppPurchaseEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/InAppPurchase/InAppPurchaseEditorCmp.vue";
import InAppPurchasePropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/InAppPurchase/InAppPurchasePropsCmp.vue";
import CircularProgressEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Basic/CircularProgress/CircularProgressEditorCmp.vue";
import CircularProgressPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Basic/CircularProgress/CircularProgressPropsCmp.vue";
import LinearProgressEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Basic/LinearProgress/LinearProgressEditorCmp.vue";
import LinearProgressPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Basic/LinearProgress/LinearProgressPropsCmp.vue";
import RouletteSelectorPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Form/VerticalSelector/RouletteSelectorPropsCmp.vue";
import RouletteSelectorEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Form/VerticalSelector/RouletteSelectorEditorCmp.vue";
import ForEachEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Logic/ForEach/ForEachEditorCmp.vue";
import ForEachPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Logic/ForEach/ForEachPropsCmp.vue";
import StorageSetValueEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/StorageValue/Set/StorageSetValueEditorCmp.vue";
import StorageSetValuePropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/StorageValue/Set/StorageSetValuePropsCmp.vue";
import VarOperationEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/LogicOperation/VarOperationEditorCmp.vue";
import VarOperationPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/LogicOperation/VarOperationPropsCmp.vue";
import HasInAppPurchaseEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/HasAnAppPurchase/HasInAppPurchaseEditorCmp.vue";
import HasInAppPurchasePropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/HasAnAppPurchase/HasInAppPurchasePropsCmp.vue";
import ChartViewEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Data/ChartView/ChartViewEditorCmp.vue";
import ChartViewPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Data/ChartView/ChartViewPropsCmp.vue";
import WebBrowserEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Device/WebBrowser/WebBrowserEditorCmp.vue";
import WebBrowserPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Device/WebBrowser/WebBrowserPropsCmp.vue";
import OpenEmailEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Device/OpenEmail/OpenEmailEditorCmp.vue";
import OpenEmailPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Device/OpenEmail/OpenEmailPropsCmp.vue";
import SystemAlarmEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Notifications/SystemAlarm/SystemAlarmEditorCmp.vue";
import SystemAlarmPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Notifications/SystemAlarm/SystemAlarmPropsCmp.vue";
import InAppProductsListEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/InAppProductsList/InAppProductsListEditorCmp.vue";
import InAppProductsListPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/InAppProductsList/InAppProductsListPropsCmp.vue";
import WidgetRendererEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Navigation/WidgetRenderer/WidgetRendererEditorCmp.vue";
import WidgetRendererPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Navigation/WidgetRenderer/WidgetRendererPropsCmp.vue";
import AbTestEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Analytics/AbTest/AbTestEditorCmp.vue";
import AbTestPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Analytics/AbTest/AbTestPropsCmp.vue";
import NavigatePropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Routing/Navigate/NavigatePropsCmp.vue";
import NavigateEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Routing/Navigate/NavigateEditorCmp.vue";
import LoadingEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Navigation/Loading/LoadingEditorCmp.vue";
import LoadingPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Navigation/Loading/LoadingPropsCmp.vue";
import TableViewEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Data/TableView/TableViewEditorCmp.vue";
import TableViewPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Data/TableView/TableViewPropsCmp.vue";
import RatingEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Rating/RatingEditorCmp.vue";
import RatingPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Rating/RatingPropsCmp.vue";
import RequestAppReviewEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Device/AppReview/RequestAppReviewEditorCmp.vue";
import StopSoundEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Sound/StopSound/StopSoundEditorCmp.vue";
import StopSoundPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Sound/StopSound/StopSoundPropsCmp.vue";
import GetInAppPurchasedAmountEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/GetAnAppPurchasedAmount/GetInAppPurchasedAmountEditorCmp.vue";
import GetInAppPurchasedAmountPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/GetAnAppPurchasedAmount/GetInAppPurchasedAmountPropsCmp.vue";
import FileUploaderEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Form/FileUpload/FileUploaderEditorCmp.vue";
import FileUploaderPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Form/FileUpload/FileUploaderPropsCmp.vue";
import DbSubscribeEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/DB/DbSubscribe/DbSubscribeEditorCmp.vue";
import DbSubscribePropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/DB/DbSubscribe/DbSubscribePropsCmp.vue";
import CheckAdConsentEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/AdConsent/CheckAdConsentEditorCmp.vue";
import ShowAdConsentEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/AdConsent/ShowAdConsentEditorCmp.vue";
import CloseModalEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Navigation/CloseModal/CloseModalEditorCmp.vue";
import CloseModalPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Navigation/CloseModal/CloseModalPropsCmp.vue";
import ContentEditorPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Basic/ContentEditor/ContentEditorPropsCmp.vue";
import ContentEditorEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Basic/ContentEditor/ContentEditorEditorCmp.vue";
import InAppManageSubsEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/InAppManageSubs/InAppManageSubsEditorCmp.vue";
import InAppRestorePurchasesEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/InAppRestorePurchases/InAppRestorePurchasesEditorCmp.vue";
import DateWeeklyPickerEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Form/DateWeeklyPicker/DateWeeklyPickerEditorCmp.vue';
import DateWeeklyPickerPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Form/DateWeeklyPicker/DateWeeklyPickerPropsCmp.vue';
import ShareDialogEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Device/ShareDialog/ShareDialogEditorCmp.vue';
import ShareDialogPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Device/ShareDialog/ShareDialogPropsCmp.vue';
import SignUpPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Auth/SignUp/SignUpPropsCmp.vue";
import KeepAwakeEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Device/KeepAwake/KeepAwakeEditorCmp.vue';
import KeepAwakePropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Device/KeepAwake/KeepAwakePropsCmp.vue';
import ChatBotSendMessageEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/ChatBot/ChatBotSendMessage/ChatBotSendMessageEditorCmp.vue';
import ChatBotSendMessagePropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/ChatBot/ChatBotSendMessage/ChatBotSendMessagePropsCmp.vue';
import CheckPrivacyOptionsEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Monetization/PrivacyOptions/CheckPrivacyOptionsEditorCmp.vue';
import ShowPrivacyOptionsEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Monetization/PrivacyOptions/ShowPrivacyOptionsEditorCmp.vue';
import CheckTrialPeriodEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Monetization/CheckTrialPeriod/CheckTrialPeriodEditorCmp.vue';
import CheckTrialPeriodPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Monetization/CheckTrialPeriod/CheckTrialPeriodPropsCmp.vue';
import NativeAdsEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Monetization/NativeAds/NativeAdsEditorCmp.vue';
import NativeAdsPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Monetization/NativeAds/NativeAdsPropsCmp.vue';
import ChatEditorCmp from '@/components/DiagramDesigner/Editor/components/UI/Chat/Chat/ChatEditorCmp.vue';
import ChatPropsCmp from '@/components/DiagramDesigner/Editor/components/UI/Chat/Chat/ChatPropsCmp.vue';
import {ChatProcessor} from '@/components/DiagramDesigner/Editor/components/UI/Chat/Chat/ChatProcessor';
import EnableInAppTestModeEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Monetization/HasAnAppPurchase/EnableInAppTestModeEditorCmp.vue";
import AlarmCheckPermissionEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Notifications/Permissions/Check/AlarmCheckPermissionEditorCmp.vue';
import AlarmRequestPermissionEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Notifications/Permissions/Request/AlarmRequestPermissionEditorCmp.vue';
import AlarmCheckPermissionPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Notifications/Permissions/Check/AlarmCheckPermissionPropsCmp.vue';
import AlarmRequestPermissionPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Notifications/Permissions/Request/AlarmRequestPermissionPropsCmp.vue';
import ResourcePreloaderEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Device/ResourcePreloader/ResourcePreloaderEditorCmp.vue';
import ResourcePreloaderPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Device/ResourcePreloader/ResourcePreloaderPropsCmp.vue';
import StorageGetValueEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Logic/StorageValue/Get/StorageGetValueEditorCmp.vue';
import StorageGetValuePropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Logic/StorageValue/Get/StorageGetValuePropsCmp.vue';
import ThreeDSceneEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/ThreeD/ThreeDScene/ThreeDSceneEditorCmp.vue';
import ThreeDScenePropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/ThreeD/ThreeDScene/ThreeDScenePropsCmp.vue';
import ThreeDObjectEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/ThreeD/ThreeDObject/ThreeDObjectEditorCmp.vue';
import ThreeDObjectPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/ThreeD/ThreeDObject/ThreeDObjectPropsCmp.vue';
import WebSocketListenerEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Logic/WebSocketListener/WebSocketListenerEditorCmp.vue';
import WebSocketListenerPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Logic/WebSocketListener/WebSocketListenerPropsCmp.vue';
import IntersectionEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Analytics/Intersection/IntersectionEditorCmp.vue';
import SendEmailEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Logic/SendEmail/SendEmailEditorCmp.vue';
import SendEmailPropsCmp from '@/components/DiagramDesigner/Editor/components/UI/Logic/SendEmail/SendEmailPropsCmp.vue';
import ImagePickerEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Device/ImagePicker/ImagePickerEditorCmp.vue';
import ImagePickerPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Device/ImagePicker/ImagePickerPropsCmp.vue';
import RequestCameraPermissionsEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Device/RequestCameraPermissions/RequestCameraPermissionsEditorCmp.vue';
import RequestCameraPermissionsPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Device/RequestCameraPermissions/RequestCameraPermissionsPropsCmp.vue';
import MarketingEventEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Analytics/MarketingEvent/MarketingEventEditorCmp.vue';
import MarketingEventPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Analytics/MarketingEvent/MarketingEventPropsCmp.vue';
import ImageExplainerEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/AI/ImageExplainer/ImageExplainerEditorCmp.vue';
import ImageExplainerPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/AI/ImageExplainer/ImageExplainerPropsCmp.vue';
import ImageGeneratorEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/AI/ImageGenerator/ImageGeneratorEditorCmp.vue';
import ImageGeneratorPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/AI/ImageGenerator/ImageGeneratorPropsCmp.vue';
import PdfGeneratorEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Logic/PdfGenerator/PdfGeneratorEditorCmp.vue';
import PdfGeneratorPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Logic/PdfGenerator/PdfGeneratorPropsCmp.vue';
import ChatSendMessageEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Chat/ChatSendMessage/ChatSendMessageEditorCmp.vue";
import ChatSendMessagePropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Chat/ChatSendMessage/ChatSendMessagePropsCmp.vue";
import TextGeneratorEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/AI/TextGenerator/TextGeneratorEditorCmp.vue';
import TextGeneratorPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/AI/TextGenerator/TextGeneratorPropsCmp.vue';
import ContentSwiperStackEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Data/ContentSwiperStack/ContentSwiperStackEditorCmp.vue';
import ContentSwiperStackPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/Data/ContentSwiperStack/ContentSwiperStackPropsCmp.vue';
import AskAssistantEditorCmp
    from '@/components/DiagramDesigner/Editor/components/UI/AI/AskAssistant/AskAssistantEditorCmp.vue';
import AskAssistantPropsCmp
    from '@/components/DiagramDesigner/Editor/components/UI/AI/AskAssistant/AskAssistantPropsCmp.vue';
import MessengerBotPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/ChatBot/MessengerService/MessengerBotPropsCmp.vue";
import MessengerBotEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/ChatBot/MessengerService/MessengerBotEditorCmp.vue";
import FinishChatEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/ChatBot/ChatBotFinishChat/FinishChatEditorCmp.vue";
import ChatBotAiAssistantPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/ChatBot/ChatBotAiAssistant/ChatBotAiAssistantPropsCmp.vue";
import ChatBotAiAssistantEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/ChatBot/ChatBotAiAssistant/ChatBotAiAssistantEditorCmp.vue";
import ChatBotAiContextEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/ChatBot/ChatBotAiContext/ChatBotAiContextEditorCmp.vue";
import ChatBotAiContextPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/ChatBot/ChatBotAiContext/ChatBotAiContextPropsCmp.vue";
import ChatBotPurchaseEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/ChatBot/ChatBotPurchase/ChatBotPurchaseEditorCmp.vue";
import ChatBotPurchasePropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/ChatBot/ChatBotPurchase/ChatBotPurchasePropsCmp.vue";
import EventSchedulerEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/EventScheduler/EventSchedulerEditorCmp.vue";
import EventSchedulerPropsCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Logic/EventScheduler/EventSchedulerPropsCmp.vue";
import VoiceChatEditorCmp
    from "@/components/DiagramDesigner/Editor/components/UI/Chat/VoiceChat/VoiceChatEditorCmp.vue";
import VoiceChatPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Chat/VoiceChat/VoiceChatPropsCmp.vue";

export const designerUiComponentsList = [
    {
        title: "Containers",
        purpose: ["ui"],
        type: 'g-containers', expanded: true, children: [
            {
                title: "Fragment",
                type: "Fragment",
                allowed: ['*'],
                forbidden: ['Fragment'],
                component: FragmentEditorCmp,
                properties: FragmentPropsCmp,
                processor: FragmentProcessor
            },
            {
                title: "Container",
                type: "Container",
                allowed: ['*'],
                component: ContainerEditorCmp,
                properties: ContainerPropsCmp
            },
            {
                title: "Teleport",
                type: "Teleport",
                component: TeleportEditorCmp,
                properties: TeleportPropsCmp
            },
            {
                title: "Carousel",
                type: "Carousel",
                allowed: ['*'],
                component: CarouselEditorCmp,
                properties: CarouselPropsCmp,
                isRepeater: true,
            },
            {
                hidden: true,
                title: "Children container",
                type: "ChildrenContainer",
                allowed: ['*'],
                component: ChildrenContainerEditorCmp,
                properties: ChildrenContainerPropsCmp
            },
        ]
    },

    {
        title: "Basic",
        purpose: ["ui"],
        type: 'g-basic', expanded: true, children: [
            {
                title: "Text",
                type: "Text",
                component: TextEditorCmp,
                properties: TextPropsCmp
            },
            {
                title: "Rich text",
                type: "RichText",
                component: RichTextEditorCmp,
                properties: RichTextPropsCmp
            },
            {
                title: "Content editor",
                type: "ContentEditor",
                component: ContentEditorEditorCmp,
                properties: ContentEditorPropsCmp
            },
            {
                title: "Image",
                type: "Image",
                component: ImageEditorCmp,
                properties: ImagePropsCmp
            },
            {
                title: "Icon",
                type: "Icon",
                component: IconEditorCmp,
                properties: IconPropsCmp
            },
            {
                title: "Video",
                type: "Video",
                component: VideoEditorCmp,
                properties: VideoPropsCmp
            },
            {
                title: "Lottie animation",
                type: "Lottie",
                component: LottieAnimationEditorCmp,
                properties: LottieAnimationPropsCmp
            },
            {
                title: "Button",
                type: "Button",
                component: ButtonEditorCmp,
                properties: ButtonPropsCmp
            },
            {
                title: "Separator",
                type: "Separator",
                component: SeparatorEditorCmp,
                properties: SeparatorPropsCmp
            },
            {
                title: "Circular progress",
                type: "CircularProgress",
                component: CircularProgressEditorCmp,
                properties: CircularProgressPropsCmp
            },
            {
                title: "Linear progress",
                type: "LinearProgress",
                component: LinearProgressEditorCmp,
                properties: LinearProgressPropsCmp
            },
        ]
    },
    {
        title: "Data",
        purpose: ["ui"],
        type: 'g-data', expanded: true, children: [
            {
                title: "Repeater",
                type: "Repeater",
                allowed: ['*'],
                component: RepeaterEditorCmp,
                properties: RepeaterPropsCmp,
                isRepeater: true,
            },
            {
                title: "Tree view",
                type: "TreeView",
                component: TreeViewEditorCmp,
                properties: TreeViewPropsCmp,
                isRepeater: true,
            },
            {
                title: "Table view",
                type: "TableView",
                component: TableViewEditorCmp,
                properties: TableViewPropsCmp,
                isRepeater: true,
            },
            {
                title: "Chart view",
                type: "ChartView",
                component: ChartViewEditorCmp,
                properties: ChartViewPropsCmp
            },
            {
                title: "Content swiper stack",
                type: "ContentSwiperStack",
                allowed: ['*'],
                component: ContentSwiperStackEditorCmp,
                properties: ContentSwiperStackPropsCmp,
                isRepeater: true,
            },
        ]
    },
    {
        title: "Navigation",
        purpose: ["ui", "logic"],
        type: 'g-navigation', expanded: true, children: [
            {
                purpose: ["ui"],
                title: "Floating action button",
                type: "FAB",
                component: FABEditorCmp,
                properties: FABPropsCmp
            },
            {
                purpose: ["ui"],
                title: "Regular dialog",
                type: "RegularDialog",
                component: DialogEditorCmp,
                properties: DialogPropsCmp
            },
            {
                purpose: ["ui"],
                title: "Close modal fragment",
                type: "CloseModal",
                component: CloseModalEditorCmp,
                properties: CloseModalPropsCmp
            },
            {
                purpose: ["ui", "logic"],
                title: "Delayed operation",
                type: "DelayedOperation",
                component: DelayEditorCmp,
                properties: DelayPropsCmp
            },
            {
                purpose: ["logic", "deferred"],
                title: "Event scheduler",
                type: "EventScheduler",
                component: EventSchedulerEditorCmp,
                properties: EventSchedulerPropsCmp
            },
            {
                purpose: ["ui", "logic"],
                title: "Custom event",
                type: "CustomEvent",
                component: CustomEventEditorCmp,
                properties: CustomEventPropsCmp
            },
            {
                purpose: ["ui", "logic"],
                title: "Diagram component",
                type: "DiagramComponent",
                component: DiagramComponentEditorCmp,
                properties: DiagramComponentPropsCmp,
                processor: DiagramComponentProcessor
            },
            {
                title: "App header",
                type: "AppHeader",
                purpose: ["ui"],
                component: AppHeaderEditorCmp,
                properties: AppHeaderPropsCmp
            },
            {
                title: "Navigation panel",
                type: "NavigationPanel",
                purpose: ["ui"],
                component: NavigationPanelEditorCmp,
                properties: NavigationPanelPropsCmp
            },
            {
                title: "Widget renderer",
                type: "WidgetRenderer",
                purpose: ["ui"],
                component: WidgetRendererEditorCmp,
                properties: WidgetRendererPropsCmp
            },
            {
                title: "Navigate to route",
                type: "NavigateTo",
                purpose: ["ui"],
                component: NavigateEditorCmp,
                properties: NavigatePropsCmp
            },
            {
                title: "Loading screen",
                type: "Loading",
                purpose: ["ui"],
                component: LoadingEditorCmp,
                properties: LoadingPropsCmp
            }
        ]
    },
    {
        title: "Form",
        purpose: ["ui"],
        type: 'g-form', expanded: true, children: [
            {
                title: "Form container",
                type: "Form",
                allowed: ['*'],
                component: FormEditorCmp,
                properties: FormPropsCmp
            },
            {
                title: "Text input field",
                type: "TextInput",
                component: InputEditorCmp,
                properties: InputPropsCmp
            },
            {
                title: "File uploader",
                type: "FileUploader",
                component: FileUploaderEditorCmp,
                properties: FileUploaderPropsCmp
            },
            {
                title: "Check box",
                type: "CheckBox",
                component: CheckBoxEditorCmp,
                properties: CheckBoxPropsCmp
            },
            {
                title: "Select box",
                type: "SelectBox",
                component: SelectEditorCmp,
                properties: SelectPropsCmp
            },
            {
                title: "Roulette selector",
                type: "RouletteSelector",
                component: RouletteSelectorEditorCmp,
                properties: RouletteSelectorPropsCmp
            },
            {
                title: "Rating",
                type: "Rating",
                component: RatingEditorCmp,
                properties: RatingPropsCmp
            },
            {
                title: "Slider",
                type: "Slider",
                component: SliderEditorCmp,
                properties: SliderPropsCmp
            },
            {
                title: "Date picker",
                type: "DatePicker",
                component: DatePickerEditorCmp,
                properties: DatePickerPropsCmp
            },
            {
                title: "Date weekly picker",
                type: "DateWeeklyPicker",
                component: DateWeeklyPickerEditorCmp,
                properties: DateWeeklyPickerPropsCmp
            },
        ]
    },
    {
        title: "Analytics",
        purpose: ["ui", "logic"],
        type: 'g-analytics', expanded: true, children: [
            {
                title: "AB Test",
                type: "AbTest",
                component: AbTestEditorCmp,
                properties: AbTestPropsCmp
            },
            {
                title: "Intersection",
                type: "Intersection",
                purpose: ["ui"],
                component: IntersectionEditorCmp,
            },
            {
                title: "Marketing event",
                type: "MarketingEvent",
                component: MarketingEventEditorCmp,
                properties: MarketingEventPropsCmp,
            },
        ]
    },
    {
        title: "Logic",
        purpose: ["ui", "logic"],
        type: 'g-logic', expanded: true, children: [
            {
                title: "Code function",
                type: "CodeFunction",
                component: CodeFunctionEditorCmp,
                properties: CodeFunctionPropsCmp,
                processor: CodeFunctionProcessor
            },
            {
                title: "Logic block",
                type: "FakeLogic",
                component: FakeLogicEditorCmp,
                properties: FakeLogicPropsCmp
            },
            {
                title: "For each",
                type: "ForEach",
                component: ForEachEditorCmp,
                properties: ForEachPropsCmp
            },
            /*{
                title: "Diagram function",
                type: "DiagramFunction",
                allowed: ['*'],
                component: DiagramFunctionEditorCmp,
                properties: DiagramFunctionPropsCmp
            },*/
            {
                title: "Logic condition",
                type: "LogicCondition",
                component: LogicConditionEditorCmp,
                properties: LogicConditionPropsCmp
            },
            {
                title: "Set storage value",
                type: "StorageSetValue",
                component: StorageSetValueEditorCmp,
                properties: StorageSetValuePropsCmp
            },
            {
                title: "Get storage value",
                type: "StorageGetValue",
                component: StorageGetValueEditorCmp,
                properties: StorageGetValuePropsCmp,
            },
            /*{
                title: "Get storage value",
                type: "StorageGetValue",
                component: StorageGetValueEditorCmp,
                properties: StorageGetValuePropsCmp
            },*/
            /*{
                title: "Local constant",
                type: "LocalConstant",
                component: LocalConstantEditorCmp,
                properties: LocalConstantPropsCmp
            },/**/
            /*{
                title: "Local variable",
                type: "LocalVariable",
                component: LocalVariableEditorCmp,
                properties: LocalVariablePropsCmp
            },
            {
                title: "Simple condition",
                type: "SimpleCondition",
                component: SimpleConditionEditorCmp,
                properties: SimpleConditionPropsCmp
            },*/
            {
                title: "Variable operation",
                type: "VarOperation",
                component: VarOperationEditorCmp,
                properties: VarOperationPropsCmp
            },
            /*{
                title: "Variable formatter",
                type: "VariableFormatter",
                component: VariableFormatEditorCmp,
                properties: VariableFormatPropsCmp
            },*/
            {
                purpose: ["ui"],
                title: "WebSocket listener",
                type: "WebSocketListener",
                component: WebSocketListenerEditorCmp,
                properties: WebSocketListenerPropsCmp,
            },
            {
                title: "Send email",
                type: "SendEmail",
                component: SendEmailEditorCmp,
                properties: SendEmailPropsCmp,
            },
            {
                title: "PDF generator",
                type: "PdfGenerator",
                component: PdfGeneratorEditorCmp,
                properties: PdfGeneratorPropsCmp,
            },
        ]
    },
    {
        title: "Monetization",
        purpose: ["ui"],
        type: 'g-monetization', expanded: true, children: [
            {
                title: "Fullscreen Ad",
                type: "FullscreenAd",
                component: FullscreenAdEditorCmp,
                properties: FullscreenAdPropsCmp
            },
            {
                title: "Banner",
                type: "AdBanner",
                allowed: ['*'],
                component: BannerEditorCmp,
                properties: BannerPropsCmp
            },
            {
                title: "Native Ads",
                type: "NativeAds",
                allowed: ['*'],
                component: NativeAdsEditorCmp,
                properties: NativeAdsPropsCmp
            },
            {
                title: "Check Ad consent",
                type: "CheckAdConsent",
                component: CheckAdConsentEditorCmp
            },
            {
                title: "Show Ad consent dialog",
                type: "ShowAdConsent",
                component: ShowAdConsentEditorCmp
            },

            {
                title: "In app purchase",
                type: "InAppPurchase",
                component: InAppPurchaseEditorCmp,
                properties: InAppPurchasePropsCmp
            },
            {
                title: "In app products list",
                type: "InAppProductsList",
                allowed: ['*'],
                component: InAppProductsListEditorCmp,
                properties: InAppProductsListPropsCmp
            },
            {
                title: "Check in app purchased",
                type: "HasInAppPurchase",
                component: HasInAppPurchaseEditorCmp,
                properties: HasInAppPurchasePropsCmp
            },
            {
                title: "Enable In App test mode",
                type: "EnableInAppTestMode",
                component: EnableInAppTestModeEditorCmp,
            },
            {
                title: "Get purchased amount",
                type: "GetInAppPurchasedAmount",
                component: GetInAppPurchasedAmountEditorCmp,
                properties: GetInAppPurchasedAmountPropsCmp
            },
            {
                title: "Manage In App subscriptions",
                type: "InAppManageSubs",
                component: InAppManageSubsEditorCmp,
            },
            {
                title: "Restore In App purchases",
                type: "InAppRestorePurchases",
                component: InAppRestorePurchasesEditorCmp,
            },
            {
                title: "Check Privacy Options",
                type: "CheckPrivacyOptions",
                component: CheckPrivacyOptionsEditorCmp,
            },
            {
                title: "Show Privacy Options",
                type: "ShowPrivacyOptions",
                component: ShowPrivacyOptionsEditorCmp,
            },
            {
                title: "Check trial period",
                type: "CheckTrialPeriod",
                component: CheckTrialPeriodEditorCmp,
                properties: CheckTrialPeriodPropsCmp,
            },
        ]
    },
    {
        title: "Auth",
        purpose: ["ui"],
        type: 'g-auth', expanded: true, children: [
            {
                title: "Sign in",
                type: "SignInFunction",
                component: SignInEditorCmp,
                properties: SignInPropsCmp
            },
            {
                title: "Sign up",
                type: "SignUpFunction",
                component: SignUpEditorCmp,
                properties: SignUpPropsCmp
            },
            {
                title: "Check role",
                type: "CheckRoleFunction",
                component: CheckRoleEditorCmp,
                properties: CheckRolePropsCmp
            },
            {
                title: "Logout",
                type: "LogoutFunction",
                component: LogoutEditorCmp
            }
        ]

    },
    /*{
        title: "API",
        purpose: ["ui"],
        type: 'g-api', expanded: true, children: [
            {
                title: "API Request",
                type: "ApiRequest",
                allowed: ['*'],
                component: ApiRequestEditorCmp,
                properties: ApiRequestPropsCmp
            },
            {
                title: "API Response",
                type: "ApiResponse",
                allowed: ['*'],
                component: ApiResponseEditorCmp
            },
        ]
    },*/
    {
        title: "Database",
        purpose: ["ui", "logic"],
        type: 'g-db', expanded: true, children: [
            {
                title: "Save to database",
                type: "DbSave",
                component: DbSaveEditorCmp,
                properties: DbSavePropsCmp
            },
            {
                title: "Delete record",
                type: "DbDelete",
                component: DbDeleteEditorCmp,
                properties: DbDeletePropsCmp,
                processor: DbDeleteProcessor
            },
            {
                title: "Query from database",
                type: "DbQuery",
                component: DbQueryEditorCmp,
                properties: DbQueryPropsCmp
            },
            {
                title: "Subscribe to data",
                type: "DbSubscribe",
                component: DbSubscribeEditorCmp,
                properties: DbSubscribePropsCmp
            },
        ]
    },
    {
        title: "Sound & Vibration",
        purpose: ["ui"],
        type: 'g-sound', expanded: true, children: [
            {
                title: "Play sound",
                type: "PlaySound",
                component: PlaySoundEditorCmp,
                properties: PlaySoundPropsCmp
            },
            {
                title: "Stop sound",
                type: "StopSound",
                component: StopSoundEditorCmp,
                properties: StopSoundPropsCmp
            },
            {
                title: "Vibrate",
                type: "Vibrate",
                component: VibrateEditorCmp,
                properties: VibratePropsCmp
            },
            {
                title: "Sound manager",
                type: "SoundManager",
                component: SoundManagerEditorCmp,
                properties: SoundManagerPropsCmp
            }
        ]
    },
    {
        title: "Native",
        purpose: ["ui"],
        type: 'g-device', expanded: true, children: [
            {
                title: "Native component",
                type: "NativeComponent",
                component: NativeComponentEditorCmp,
                properties: NativeComponentPropsCmp
            },
            {
                title: "Check permission",
                type: "CheckPermission",
                component: CheckPermissionEditorCmp,
                properties: CheckPermissionPropsCmp
            },
            {
                title: "Request permission",
                type: "RequestPermission",
                component: RequestPermissionEditorCmp,
                properties: RequestPermissionPropsCmp
            },

            {
                title: "Device navigator",
                type: "DeviceNavigator",
                component: DeviceNavigatorEditorCmp,
                properties: DeviceNavigatorPropsCmp
            },
            {
                title: "Request app review",
                type: "RequestAppReview",
                component: RequestAppReviewEditorCmp
            },
            {
                title: "Web browser",
                type: "WebBrowser",
                component: WebBrowserEditorCmp,
                properties: WebBrowserPropsCmp
            },
            {
                title: "Open email client",
                type: "OpenEmail",
                component: OpenEmailEditorCmp,
                properties: OpenEmailPropsCmp
            },
            {
                title: "Share Dialog",
                type: "ShareDialog",
                component: ShareDialogEditorCmp,
                properties: ShareDialogPropsCmp,
            },
            {
                title: "Keep Awake",
                type: "KeepAwake",
                component: KeepAwakeEditorCmp,
                properties: KeepAwakePropsCmp,
            },
            {
                title: "Resource preloader",
                type: "ResourcePreloader",
                component: ResourcePreloaderEditorCmp,
                properties: ResourcePreloaderPropsCmp,
            },
            {
                title: "Request camera permissions",
                type: "RequestCameraPermissions",
                component: RequestCameraPermissionsEditorCmp,
                properties: RequestCameraPermissionsPropsCmp,
            },
            {
                title: "Image picker",
                type: "ImagePicker",
                component: ImagePickerEditorCmp,
                properties: ImagePickerPropsCmp,
            },
        ]
    },
    {
        title: "Notifications",
        purpose: ["ui"],
        type: 'g-notifications',
        expanded: true,
        children: [
            {
                title: "System alarm",
                type: "SystemAlarm",
                component: SystemAlarmEditorCmp,
                properties: SystemAlarmPropsCmp
            },
            {
                title: "Alarm: check permission",
                type: "AlarmCheckPermission",
                component: AlarmCheckPermissionEditorCmp,
                properties: AlarmCheckPermissionPropsCmp,
            },
            {
                title: "Alarm: request permission",
                type: "AlarmRequestPermission",
                component: AlarmRequestPermissionEditorCmp,
                properties: AlarmRequestPermissionPropsCmp,
            },
        ]
    },
    {
        title: 'Chat bot',
        purpose: ['logic'],
        type: 'g-chat-bot',
        expanded: true,
        children: [
            {
                title: 'Messenger Bot',
                type: 'MessengerBot',
                component: MessengerBotEditorCmp,
                properties: MessengerBotPropsCmp,
            },
            {
                title: 'Send message',
                type: 'ChatBotSendMessage',
                component: ChatBotSendMessageEditorCmp,
                properties: ChatBotSendMessagePropsCmp,
            },
            {
                title: 'AI Chat Assistant',
                type: 'ChatBotAiAssistant',
                component: ChatBotAiAssistantEditorCmp,
                properties: ChatBotAiAssistantPropsCmp,
            },
            {
                title: 'AI Context',
                type: 'ChatBotAiContext',
                component: ChatBotAiContextEditorCmp,
                properties: ChatBotAiContextPropsCmp,
            },
            {
                title: 'Purchase in Chat',
                type: 'ChatBotPurchase',
                component: ChatBotPurchaseEditorCmp,
                properties: ChatBotPurchasePropsCmp,
            },
            {
                title: 'Finish Chat',
                type: 'FinishChat',
                component: FinishChatEditorCmp,
            },
        ]
    },
    {
        title: 'Chat',
        purpose: ['ui'],
        type: 'g-chat-ui',
        expanded: true,
        children: [
            {
                title: 'Chat',
                type: 'Chat',
                component: ChatEditorCmp,
                properties: ChatPropsCmp,
                processor: ChatProcessor,
            },
            {
                title: 'VoiceChat',
                type: 'VoiceChat',
                component: VoiceChatEditorCmp,
                properties: VoiceChatPropsCmp,
            },
            {
                title: 'Send chat message',
                type: 'ChatSendMessage',
                component: ChatSendMessageEditorCmp,
                properties: ChatSendMessagePropsCmp
            },
        ]
    },
    {
        purpose: ["ui"],
        type: "RemoteChildren",
        hidden: true,
        allowed: ['*'],
    },
    {
        purpose: ["ui", "logic"],
        type: "link",
        hidden: true,
        properties: LinkPropsCmp
    },
    {
        purpose: ["ui", 'logic'],
        title: "Diagram",
        type: "root",
        hidden: true,
        allowed: ['*'],
        properties: DiagramPropsCmp
    },
    {
        title: "3D",
        purpose: ["ui"],
        type: 'g-three-d', expanded: true, children: [
            {
                title: "3D scene",
                type: "ThreeDScene",
                allowed: ['ThreeDObject'],
                forbidden: ['*'],
                component: ThreeDSceneEditorCmp,
                properties: ThreeDScenePropsCmp,
            },
            {
                title: "3D object",
                type: "ThreeDObject",
                component: ThreeDObjectEditorCmp,
                properties: ThreeDObjectPropsCmp,
            },
        ]
    },
    {
        title: "AI",
        purpose: ["ui", "logic"],
        type: 'g-ai', expanded: true, children: [
            {
                title: "Image explainer",
                type: "ImageExplainer",
                forbidden: ['*'],
                component: ImageExplainerEditorCmp,
                properties: ImageExplainerPropsCmp,
            },
            {
                title: "Image generator",
                type: "ImageGenerator",
                forbidden: ['*'],
                component: ImageGeneratorEditorCmp,
                properties: ImageGeneratorPropsCmp,
            },
            {
                title: "Text generator",
                type: "TextGenerator",
                forbidden: ['*'],
                component: TextGeneratorEditorCmp,
                properties: TextGeneratorPropsCmp,
            },
            {
                title: "Ask assistant",
                type: "AskAssistant",
                forbidden: ['*'],
                component: AskAssistantEditorCmp,
                properties: AskAssistantPropsCmp,
            },
        ]
    },
    {
        title: "Deprecated",
        purpose: ["ui"],
        hidden: true,
        type: 'g-ai', expanded: true, children: [
            {
                title: "Submit button",
                type: "SubmitButton",
                component: SubmitEditorCmp,
                properties: SubmitPropsCmp,
                hidden: true
            },
        ]
    },
]
