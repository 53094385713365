<template>

  <q-card class="full-width">
    <q-card-section class="bg-primary text-white row items-center">
      <div class="text-h6">Edit table</div>
      <q-space/>
      <q-btn icon="close" flat round @click="$emit('saved')"/>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <q-form ref="dbTableForm" @submit="onSaveDbTable" class="q-gutter-md">

        <q-input
            v-model="mutate(editDbTable).name"
            label="Table name *"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
        />

        <q-checkbox v-model="mutate(editDbTable).physical_delete" :true-value="1" :false-value="0" label="Physical deletion of records"/>
        <q-checkbox v-model="mutate(editDbTable).realtime_sync" :true-value="1" :false-value="0" label="Realtime sync"/>

        <db-permission-settings v-model="mutate(editDbTable).permissions"/>


        <!--q-select
            filled
            v-model="mutate(editDbTable).remote_module_id"
            label="Remote storage module *"
            lazy-rules
            map-options
            emit-value
            :options="modules"/!-->

        <div>
          <q-btn label="Save" type="submit" color="primary"/>
        </div>
      </q-form>

    </q-card-section>

  </q-card>

</template>

<script>

import {DbTable} from "@/../../common/db/DbTable";
import {nanoid} from "nanoid";
import {AppModule} from "../../../../../common/db/AppModule";
import DbPermissionSettings from "@/pages/workspace/dbs/DbPermissionSettings.vue";

export default {
  name: "DbTableForm",
  components: {DbPermissionSettings},
  props: ['editDbTable', 'appId'],
  emits: ['saved'],
  computed: {

    /**
     * Module list
     * @returns {*}
     */
    modules() {
      return this.wait("modules", async () => {
        const list = (await AppModule.query().where({app_id: this.appId}, {type: 'server'}).get()).map(m => ({value: m.id, label: m.name}))
        return [{value: 0, label: "Local table"}].concat(list);
      }, [])
    }
  },
  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveDbTable() {

      if(!this.editDbTable.id) {
        this.mutate(this.editDbTable).unique_id = nanoid(10);
      }

      // Update
      await DbTable.remote().save(this.editDbTable)

      // Hide dialog
      this.$emit('saved')
    }
  }
}

</script>
