<template>

  <ab-flow-base-cmp class="input-editor-cmp" :block="block" :transparent="true" :class="classesString" :style="stylesString">
    <q-input
      :label="inputLabel"
      :type="fieldType"
      :autogrow="isAutoGrow"
      :placeholder="inputPlaceholder"
      :dense="true"
      :hint="inputHint"
      class="full-width"
      :standout="standout"
    />

    <div class="links-wrapper">
      <ab-flow-link-connector :block="block" type="output" event="focus" class="q-mr-sm"/>
      <ab-flow-link-connector :block="block" type="output" event="changed" class="q-mr-sm"/>
      <ab-flow-link-connector v-for="(e, k) of block.properties?.keypress || []" class="q-mr-xs" :block="block" type="output" :event="`press-${e.name}`" :key="k"/>
    </div>

  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  mixins: [renderMixins],
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  props: ['block'],
  name: "InputEditorCmp",
  computed: {

    /**
     * Field type
     * @return {string}
     */
    fieldType() {
      // if password
      if (this.block.properties?.isPassword) {
        return 'password';
      }

      return this.block.properties?.isMultyLine ? "textarea" : "text"
    },

    /**
     * is autogrow
     * @return {*}
     */
    isAutoGrow() {
      return this.block.properties?.isAutoGrow ? true : false
    },

    /**
     * input label
     * @return {*|string}
     */
    inputLabel() {
      return this.interpretString(this.block.properties?.label)
    },

    /**
     * input placeholder
     * @return {*|string}
     */
    inputPlaceholder() {
      return this.interpretString(this.block.properties?.placeholder)
    },

    /**
     * input hint
     * @return {*|string}
     */
    inputHint() {
      return this.interpretString(this.block.properties?.hint) || undefined
    },

    /**
     * input name
     * @return {*|string}
     */
    inputName() {
      return this.block.properties ? this.block.properties.name : "default"
    },

    /**
     * Determines the standout value for the block.
     * The standout value is parsed from the block's properties.
     * If the standout property is not set or is not a valid integer, it defaults to 0.
     * The result is then converted to a boolean value.
     * @return {boolean} The standout value for the block.
     */
    standout() {
      return !!(parseInt(this.block.properties?.standout) || 0);
    },
  }
}

</script>

<style lang="scss">

.input-editor-cmp {
  position: relative;

  .q-field--standout .q-field__control {
    background: transparent !important;
    box-shadow: none !important;

    &:before {
      background: transparent !important;
    }
  }

  .links-wrapper {
    right: 0;
    left: auto;
  }

  .q-field {
    .q-field__messages {
      color: var(--control-text-color);
    }

    .q-field__control {
      color: var(--control-focus-color, transparent);
      height: 100%;
      margin-left: 5px;
      margin-right: 5px;

      .q-field__native, .q-field__label {
        color: var(--control-text-color) !important;
      }

      &:before {
        border-color: currentColor !important;
      }
    }

    &:not(.q-field--focused) {
      .q-field__control {
        &:before {
          border-color: var(--control-unfocused-color, transparent) !important;
        }
      }
    }
  }
}


</style>
