import {optionsCollections} from "../../a2u-renderer-common/src/constants/optionsCollections.js";
import {countriesList} from "./countries.js";
import {languagesList} from "./languages.js";
import {currenciesList} from "./currencies.js";

export const constants = {

    options: {

        collections: optionsCollections(),
        adProviders: [
            {value: "AdMob", label: "Admob"},
            {value: "Cas", label: "CAS"},
            {value: "Appodeal", label: "Appodeal"},
        ],
        priorities: [
            {value: "low", label: "Low"},
            {value: "medium", label: "Medium"},
            {value: "high", label: "High"},
            {value: "top", label: "Top"},
        ],
        aiProviders: [
            {value: "open-ai", label: "OpenAI"},
        ],
        aiModels: [
            {value: "gpt-4o-mini", label: "gpt-4o-mini"},
            {value: "gpt-4o", label: "gpt-4o"},
            ],
        contextWindowOperations: [
            {value: "set", label: "Set"},
            {value: "add", label: "Add"}
            ],
        platformList: [
            {value: "ios", label: "iOS"},
            {value: "android", label: "Android"},
            {value: "web", label: "Web"},
        ],

        animationsList: [
            {value: "rotation", label: "Rotation"},
        ],

        soundManagerChannels: [
            {value: "sound", label: "Sound"},
            {value: "music", label: "Music"},
            {value: "vibration", label: "Vibration"},
        ],

        dateConstantOptions: [
            {value: "currentDateTime", label: "Current date & time"},
            {value: "currentDate", label: "Current date"},
            {value: "customDate", label: "Custom date"},
            {value: "customUnit", label: "Custom unit"},
        ],

        optionsCollections: [

            {value: "countries", label: "Countries"},
            {value: "currencies", label: "Currencies"},
            {value: "languages", label: "Languages"},

            {value: "years", label: "Years"},
            {value: "months", label: "Months"},
            {value: "days", label: "Days"},
            {value: "hours", label: "Hours"},
            {value: "minutes", label: "Minutes"},
            {value: "seconds", label: "Seconds"},
            {value: "daysOfWeek", label: "Days of week"},
            {value: "weightUnits", label: "Weight units"},
            {value: "fluidUnits", label: "Fluid units"},
            {value: "lengthUnits", label: "Length units"},
            {value: "numbers100", label: "Numbers 1-100"},
            {value: "numbers200", label: "Numbers 1-200"},
            {value: "numbers500", label: "Numbers 1-500"},
            {value: "numbers1000", label: "Numbers 1-1000"},
            {value: "routing-path", label: "Routing history"},
        ],

        dateConstantUnits: [
            {value: "second", label: "Second"},
            {value: "minute", label: "Minute"},
            {value: "hour", label: "Hour"},
            {value: "day", label: "Day"},
            {value: "week", label: "Week"},
            {value: "month", label: "Month"},
            {value: "year", label: "Year"},
        ],

        separatorStyles: [
            {value: "", label: "Empty"},
            {value: "line", label: "Line"},
        ],

        dbSaveModes: [
            {value: "object", label: "Object"},
            {value: "fields", label: "Fields"},
        ],

        directions: [
            {value: "horizontal", label: "Horizontal"},
            {value: "vertical", label: "Vertical"},
        ],

        soundManagerOperations: [
            {value: "volume", label: "Volume"},
            {value: "mute", label: "Mute"},
        ],

        vibrationTypes: [
            {value: "small", label: "Small"},
            {value: "medium", label: "Medium"},
            {value: "long", label: "Long"},
        ],

        touchEvents: [
            {value: "click", label: "Click"},
            {value: "start-click", label: "Start click"},
            {value: "end-click", label: "End click"},
            {value: "long-click", label: "Long click"},
        ],

        variableOperations: [
            {value: "+", label: "Add", types: ["number"], array: true},
            {value: "*", label: "Multiply", types: ["number"]},
            {value: "/", label: "Divide", types: ["number"]},
            {value: "set", label: "Set"},
            {value: "reset", label: "Reset"},
            {value: "toggle", label: "Toggle", types: ["bool", "string", "int"], array: true},
        ],
        varOperations: [
            {value: "+", label: "Add"},
            {value: "-", label: "Subtract"},
            {value: "*", label: "Multiply"},
            {value: "/", label: "Divide"},
        ],
        logicOperations: [
            {value: "=", label: "Equal", mathOperation: "="},
            {value: "!=", label: "Not equal", mathOperation: "!="},
            {value: ">", label: "Greater", mathOperation: ">"},
            {value: ">=", label: "Greater or equal", mathOperation: ">="},
            {value: "<", label: "Less", mathOperation: "<"},
            {value: "<=", label: "Less or equal", mathOperation: "<="},
        ],
        boolValues: [
            {value: true, label: "True"},
            {value: false, label: "False"},
        ],
        valueTypes: [
            {value: "none", label: "None", type: "setter"},
            {value: "static", label: "Constant", type: "getter"},
            {value: "variable", label: "Variable"},
            {value: "input", label: "Input value", type: "getter"},
            {value: "condition", label: "Condition"},
            {value: "function", label: "Function call", type: "getter"},
            {value: "expression", label: "Expression", type: "getter"}
            /*{value: "operation", label: "Operation", type: "getter"},*/
            /*{value: "db", label: "DB query", type: "getter"},*/
        ],

        valueOperations: [
            {value: "find", label: "Find element in array"},
            {value: "filter", label: "Filter array"},
            {value: "property", label: "Get property from object"},
        ],

        dialogMods: [
            {value: "alert", label: "Alert"},
            {value: "confirm", label: "Confirm"},
            {value: "prompt", label: "Prompt"},
        ],
        fragmentLayouts: [
            {value: "mobile-portrait", label: "Mobile portrait", width: 360, height: 740},
            {value: "mobile-landscape", label: "Mobile portrait", width: 740, height: 360},
            {value: "desktop", label: "Desktop", width: 800, height: 600},
        ],
        moduleLayouts: [
            {value: "portrait", label: "Portrait"},
            {value: "landscape", label: "Landscape"},
            {value: "auto", label: "Auto"},
        ],
        statusBarModes: [
            {value: "auto", label: "Auto"},
            {value: "light", label: "Light"},
            {value: "dark", label: "Dark"},
        ],
        messengers: [
            {value: "telegram", label: "Telegram"},
            {value: "ab-chat", label: "A2U Chat"},
        ],
        labelPosition: [
            {value: "left", label: "Left"},
            {value: "right", label: "Right"}
        ],
        routeNavigationModes: [
            {value: "replace", label: "Replace current route"},
            {value: "push", label: "Push to history"},
        ],
        toolbarMenuPosition: [
            {value: "", label: "None"},
            {value: "left", label: "Left"},
            {value: "right", label: "Right"}
        ],
        adTypes: [
            {value: "banner", label: "Banner", place: "page"},
            {value: "interstitial", label: "Interstitial", place: "fullscreen"},
            {value: "rewarded", label: "Rewarded", place: "fullscreen"},
            {value: "interstitial-rewarded", label: "Interstitial rewarded", place: "fullscreen"},
            {value: "interstitial-native", label: "Interstitial native", place: "fullscreen"},
            {value: "appopen", label: "App open", place: "fullscreen"},
            {value: "nativeads", label: "Native Ads", place: "page"},
        ],
        iapTypes: [
            {value: "subscription", label: "Subscription"},
            {value: "product", label: "Product"},
        ],
        positionsFAB: [
            {value: "center", label: "Center"},
            {value: "top-left", label: "Top Left"},
            {value: "top-right", label: "Top Right"},
            {value: "bottom-left", label: "Bottom Left"},
            {value: "bottom-right", label: "Bottom Right"},
        ],
        expandableDirection: [
            {value: "", label: "None"},
            {value: "up", label: "Up"},
            {value: "down", label: "Down"},
            {value: "left", label: "Left"},
            {value: "right", label: "Right"},
        ],
        positions: [
            {value: "static", label: "Static"},
            {value: "relative", label: "Relative"},
            {value: "absolute", label: "Absolute"}
        ],
        positionX: [
            {value: "left", label: "Left"},
            {value: "center", label: "Center"},
            {value: "right", label: "Right"}
        ],
        positionY: [
            {value: "top", label: "Top"},
            {value: "center", label: "Center"},
            {value: "bottom", label: "Bottom"}
        ],
        scrollValues: [
            {value: "hidden", label: "Hidden"},
            {value: "auto", label: "Auto"},
            {value: "scroll", label: "Scroll"},
        ],
        colors: [
            {value: "", label: "None"},
            {value: "primary", label: "Primary", background: "#FFFFFF", foreground: "#333333"},
            {value: "secondary", label: "Secondary", background: "#6c757d", foreground: "#444444"},
            {value: "tertiary", label: "Tertiary", background: "#d0d0d0", foreground: "#555555"},
            {value: "info", label: "Info", background: "#17a2b8", foreground: "#17a2b8"},
            {value: "success", label: "Success", background: "#28a745", foreground: "#00aa00"},
            {value: "danger", label: "Danger", background: "#dc3545", foreground: "#aa0000"},
            {value: "warning", label: "Warning", background: "#ffc107", foreground: "#ffc107"},
            {value: "dark", label: "Dark", background: "#343a40", foreground: "#000000"},
            {value: "light", label: "Light", background: "#f8f9fa", foreground: "#ffffff"},
            {value: "validation-error", label: "Validation error", background: "#ffffff", foreground: "#c10015"},
        ],
        textStyles: [
            {value: "", label: "None"},
            {value: "h1", label: "Header 1", fontSize: "3", fontWeight: "700", fontFamily: "Roboto"},
            {value: "h2", label: "Header 2", fontSize: "2.7", fontWeight: "700", fontFamily: "Roboto"},
            {value: "h3", label: "Header 3", fontSize: "2.3", fontFamily: "Roboto"},
            {value: "h4", label: "Header 4", fontSize: "2", fontFamily: "Roboto"},

            {value: "title", label: "Title", fontSize: "1.8", fontFamily: "Roboto"},
            {value: "sub-title", label: "Sub title", fontSize: "1.4", fontFamily: "Roboto"},
            {value: "regular", label: "Regular text", fontSize: "1", fontFamily: "Roboto"},
            {value: "small", label: "Small text", fontSize: "0.8", fontFamily: "Roboto"},

        ],
        fontFamilies: [
            {
                value: 'Roboto',
                label: 'Roboto',
                italic: true,
                weights: ['100', '300', '400', '500', '700', '900'],
            },
            {
                value: 'Open+Sans',
                label: 'Open Sans',
                italic: true,
                weights: ['300', '400', '500', '600', '700', '800'],
            },
            {
                value: 'Lato',
                label: 'Lato',
                italic: true,
                weights: ['100', '300', '400', '700', '900'],
            },
            {
                value: 'Montserrat',
                label: 'Montserrat',
                italic: true,
                weights: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
            },
            {
                value: 'Raleway',
                label: 'Raleway',
                italic: true,
                weights: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
            },
            {
                value: 'Playfair+Display',
                label: 'Playfair Display',
                italic: true,
                weights: ['400', '500', '600', '700', '800', '900'],
            },
            {
                value: 'Noto+Sans',
                label: 'Noto Sans',
                italic: true,
                weights: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
            },
            {
                value: 'Noto+Serif',
                label: 'Noto Serif',
                italic: true,
                weights: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
            },
            {
                value: 'Anta',
                label: 'Anta',
                italic: false,
                weights: ['400'],
            },
            {
                value: 'Nunito+Sans',
                label: 'Nunito Sans',
                italic: true,
                weights: ['200', '300', '400', '500', '600', '700', '800', '900'],
            },
            {
                value: 'Nunito',
                label: 'Nunito',
                italic: true,
                weights: ['200', '300', '400', '500', '600', '700', '800', '900'],
            },
        ],
        fontWeights: [
            {value: "100", label: "Thin (100)"},
            {value: "200", label: "Extra Light (200)"},
            {value: "300", label: "Light (300)"},
            {value: "400", label: "Regular (400)"},
            {value: "500", label: "Medium (500)"},
            {value: "600", label: "Semi-Bold (600)"},
            {value: "700", label: "Bold (700)"},
            {value: "800", label: "Extra Bold (800)"},
            {value: "900", label: "Black (900)"},
        ],
        yesno: [{value: "yes", label: "Yes"}, {value: "no", label: "No"}],
        yesnoBin: [{value: 1, label: "Yes"}, {value: 0, label: "No"}],
        ellipsisOptions: [{value: "1", label: "1 line"}, {value: "2", label: "2 lines"}, {
            value: "3",
            label: "3 lines"
        }],
        direction: [{value: "row", label: "Row"}, {value: "column", label: "Column"}, {value: "stack", label: "Stack"}],
        wrap: [
            {value: "wrap", label: "Wrap"},
            {value: "nowrap", label: "No wrap"},
        ],
        fragmentTypes: [
            {value: "page", label: "Page"},
            {value: "slide", label: "Slide"},
            {value: "sidebar", label: "Sidebar"},
            {value: "modal", label: "Modal"},
            {value: "slot", label: "Slot"},
            {value: "widget", label: "Widget"},
        ],
        functionTypes: [
            {value: "inline", label: "Inline"},
            {value: "source", label: "Source code"},
        ],
        checkbox_types: [{value: "regular", label: "Regular"}, {value: "image", label: "Image"}],
        imageSelectionStyle: [
            {value: "opacity", label: "Opacity"},
            {value: "shadow", label: "Shadow"},
            {value: "check", label: "Check"},
            {value: "image", label: "Second image"}
        ],
        shapes: [
            {value: "rectangle", label: "Rectangle"},
            {value: "rounded", label: "Rounded"},
            {value: "round", label: "Round"},
        ],
        button_types: [
            {value: "regular", label: "Regular"},
            {value: "flat", label: "Flat"},
            {value: "outline", label: "Outline"},
        ],
        menu_types: [
            {value: "vertical", label: "Vertical"},
            {value: "horizontal", label: "Horizontal"},
            {value: "context", label: "Context"},
        ],
        data_types: [
            {value: "string", label: "String"},
            {value: "int", label: "Integer"},
            {value: "datetime", label: "Date and time"},
            {value: "float", label: "Float"},
            {value: "bool", label: "Boolean"},
            {value: "object", label: "Object"},
            {value: "image", label: "Image"},
            {value: "sound", label: "Sound"},
            {value: "video", label: "Video"},
            {value: "icon", label: "Icon"},
            {value: "lottie", label: "Lottie animation"},
        ],
        media_types: [
            {value: "image", label: "Image"},
            {value: "sound", label: "Sound"},
            {value: "video", label: "Video"},
            {value: "lottie", label: "Lottie animation"},
            {value: "pdf", label: "PDF document"},
            {value: "subtitles", label: "Subtitles"},
            {value: "fbx", label: "FBX"},
        ],

        db_data_types: [
            {value: "autogenerated", label: "Autogenerated"},
            {value: "autoincrement", label: "Autoincrement"},
            {value: "secret", label: "Secret field"},
            {value: "string", label: "String"},
            {value: "text", label: "Text"},
            {value: "int", label: "Integer"},
            {value: "float", label: "Float"},
            {value: "bool", label: "Boolean"},
            {value: "json", label: "JSON"},
            {value: "datetime", label: "Date and time"},
            {value: "lottie", label: "Lottie animation"},
            {value: "image", label: "Image"},
            {value: "sound", label: "Sound"},
            {value: "file", label: "File"},
            {value: "video", label: "Video"},
            {value: "localizedString", label: "Localized string"},
            {value: "textsearch", label: "Text search"},

        ],

        module_types: [
            {value: "web", label: "Web"},
            {value: "mobile", label: "Mobile application"},
//            {value: "chat-bot", label: "Chat bot"},
            {value: "server", label: "Server"}
        ],

        module_languages: [
            {value: "vue", label: "Vue", types: ["web", "mobile"]},
            {value: "node", label: "NodeJs", types: ["server"]},
            {value: "flutter", label: "Flutter", types: ["mobile"]},
        ],

        release_types: [{value: "dev", label: "Development"}, {value: "stage", label: "Stage"}, {
            value: "release",
            label: "Release"
        }],
        event_types: [{value: "incoming", label: "Incoming"}, {
            value: "outgoing",
            label: "Outgoing"
        }, {value: "junction", label: "Junction"}],
        storage_operation_types: [{value: "set", label: "Set"}, {value: "get", label: "Get"}],
        comparison_operations: [
            {value: "==", label: "Equal"},
            {value: "!=", label: "Not equal"},
            {value: ">", label: "Greater than"},
            {value: ">=", label: "Greater than or equal"},
            {value: "<", label: "Less than"},
            {value: "<=", label: "Less than or equal"},
            {value: "contains", label: "Array contains item"},
            {value: "=== undefined", label: "Value is undefined"},
            {value: "!== undefined", label: "Value is defined"}
        ],

        sizes: [
            {value: "", label: ""},
            {value: "xs", label: "xs"},
            {value: "sm", label: "sm"},
            {value: "md", label: "md"},
            {value: "lg", label: "lg"},
            {value: "llg", label: "llg"},
            {value: "xl", label: "xl"},
            {value: "xxl", label: "xxl"},
        ],

        borderRadius: [{value: 0, label: "No"}, {value: "1px", label: "1px"}, {
            value: "2px",
            label: "2px"
        }, {value: "3px", label: "3px"}, {value: "4px", label: "4px"}, {value: "5px", label: "5px"}, {
            value: "10px",
            label: "10px"
        }, {
            value: "20px"
            , label: "20px"
        }, {value: "10%", label: "10%"}, {value: "20%", label: "20%"}, {value: "30%", label: "30%"}, {
            value: "40%",
            label: "40%"
        }, {value: "50%", label: "50%"}],

        backgroundType: [
            {value: "color", label: "Color"},
            {value: "gradient", label: "Gradient"},
            {value: "image", label: "Image"}
        ],

        borderSize: [{value: 0, label: "No"}, {value: "1px", label: "1px"}, {value: "2px", label: "2px"}, {
            value: "3px",
            label: "3px"
        }, {value: "4px", label: "4px"}, {value: "5px", label: "5px"}, {value: "10px", label: "10px"}],
        levels: [{value: "0", label: "0"}, {value: "1", label: "1"}, {value: "2", label: "2"}, {
            value: "3",
            label: "3"
        }, {value: "4", label: "4"}, {value: "5", label: "5"}],
        contentAlign: [{value: "", label: "None"}, {value: "start", label: "Start"}, {
            value: "center",
            label: "Center"
        }, {value: "end", label: "End"}],
        textAlign: [{value: "left", label: "Left"}, {value: "center", label: "Center"}, {
            value: "right",
            label: "Right"
        }],
        bgImageSize: [
            {value: "auto", label: "None"},
            {value: "100% 100%", label: "Fill"},
            {value: "cover", label: "Cover"},
            {value: "contain", label: "Contain"}
        ],
        videoFitting: [
            //{value: "fill", label: "Fill"},
            {value: "cover", label: "Cover"},
            {value: "contain", label: "Contain"}],
        sides: [
            //{value: "fill", label: "Fill"},
            {value: "top", label: "Top"},
            {value: "bottom", label: "Bottom"},
            {value: "left", label: "Left"},
            {value: "right", label: "Right"},
        ],
        mediaFitting: [
            {value: "fill", label: "Fill"},
            {value: "cover", label: "Cover"},
            {value: "contain", label: "Contain"}
        ],
        mediaPosition: [
            {value: "left top", label: "Left top"},
            {value: "left bottom", label: "Left bottom"},
            {value: "right top", label: "Right top"},
            {value: "right bottom", label: "Right bottom"},
            {value: "center top", label: "Center top"},
            {value: "center bottom", label: "Center bottom"},
            {value: "center", label: "Center"},
        ],
        modalWidths: [
            {value: "auto", label: "Auto"},
            {value: "small", label: "Small"},
            {value: "medium", label: "Medium"},
            {value: "large", label: "Large"},
            ],
        modalHeights: [
            {value: "auto", label: "Auto"},
            {value: "full", label: "Full height"},
            ],
        modalPosition: [
            {value: "center", label: "Center"},
            {value: "left", label: "Left"},
            {value: "right", label: "Right"},
            {value: "top", label: "Top"},
            {value: "bottom", label: "Bottom"},
            {value: "full", label: "Full screen"},
        ],
        textWeight: [
            {value: "thin", label: "Thin"},
            {value: "regular", label: "Regular"},
            {value: "bold", label: "Bold"},
        ],
        lineHeight: [
            {value: 1, label: 1},
            {value: 1.1, label: 1.1},
            {value: 1.2, label: 1.2},
            {value: 1.3, label: 1.3},
            {value: 1.4, label: 1.4},
            {value: 1.5, label: 1.5},
        ],
        textDecoration: [{value: "overline", label: "Overline"}, {
            value: "line-through",
            label: "Line through"
        }, {value: "underline", label: "Underline"}],
        objectFits: [{value: "", label: "None"}, {value: "fill", label: "Fill"}, {
            value: "contain",
            label: "Contain"
        }, {value: "cover", label: "Cover"}, {value: "scale-down", label: "Scale down"}],


        permissionsList: [
            {value: "usage_stats", label: "Users statistics", android: ["PACKAGE_USAGE_STATS:ProtectedPermissions"]},
            {value: "mute", label: "Allow to manage don't disturb mode", android: ["ACCESS_NOTIFICATION_POLICY"]},
            {
                value: "overlay_drawing",
                label: "Allow to draw over other apps",
                android: ["ACTION_MANAGE_OVERLAY_PERMISSION", "SYSTEM_ALERT_WINDOW"]
            },
            {
                value: "manage_external_storage",
                label: "Manage external storage",
                android: ["MANAGE_EXTERNAL_STORAGE", "READ_EXTERNAL_STORAGE", "WRITE_EXTERNAL_STORAGE"]
            },
            {value: "saf", label: "Allow to manage files"},
            {value: "post_notification", label: "Allow to post notifications", android: ["POST_NOTIFICATIONS"]},
            //{value: "exact_alarm", label: "Alarms planning", android: ["SCHEDULE_EXACT_ALARM", "USE_EXACT_ALARM"]},
            {
                value: "record_audio",
                label: "Record audio",
                android: ["RECORD_AUDIO", "MODIFY_AUDIO_SETTINGS"],
                ios: ['NSMicrophoneUsageDescription:This app requires access to the microphone to record audio messages.']
            },
            {
                value: "coarse_location",
                label: "Coarse location",
                android: ["ACCESS_FINE_LOCATION", "ACCESS_COARSE_LOCATION"]
            },
            {
                value: "write_settings",
                label: "Change system settings",
                android: ["WRITE_SETTINGS:ProtectedPermissions"]
            },
            {
                value: "idfa",
                label: "Identifier for advertisers",
                android: [],
                ios: ["NSUserTrackingUsageDescription:This identifier will be used to deliver personalized ads to you."]
            }
        ],

        countries: countriesList,
        languages: languagesList,
        currencies: currenciesList,

        transitions: [
            {label: 'Fade', value: 'fade'},
            {label: 'Scale', value: 'scale'},
            {label: 'Rotate', value: 'rotate'},
            {label: 'Slide right', value: 'slide-right'},
            {label: 'Slide left', value: 'slide-left'},
            {label: 'Slide up', value: 'slide-up'},
            {label: 'Slide down', value: 'slide-down'},
            {label: 'Flip right', value: 'flip-right'},
            {label: 'Flip left', value: 'flip-left'},
            {label: 'Flip up', value: 'flip-up'},
            {label: 'Flip down', value: 'flip-down'},
            {label: 'Jump right', value: 'jump-right'},
            {label: 'Jump left', value: 'jump-left'},
            {label: 'Jump up', value: 'jump-up'},
            {label: 'Jump down', value: 'jump-down'},
        ],
    },

    sides: ["left", "right", "top", "bottom"],
    sizes_to_pixels:
        {
            xs: 4,
            sm: 8,
            md: 16,
            lg: 24,
            llg: 36,
            xl: 48,
            xxl: 56,
        },
    size_to_font_size: {
        xs: 8,
        sm: 10,
        md: 14,
        lg: 20,
        llg: 22,
        xl: 24,
        xxl: 28,
    },
}
