import {pathHelper} from "@/utils/pathHelper";
import {constants} from "@/../../common/constants/constants";

export const renderMixins = {

    inject: {
        canvas: {
            default: null
        },
        module_id: {
            default: null
        },
        product_id: {
            default: null
        },
        parentWidget: {
            default: null
        },
        parentFragment: {
            default: null
        },
        designer: {
            default: null
        }
    },

    computed: {


        /**
         * Get dynamic panel styles
         */
        dynamicPanelStyles() {

            // Check if panel style is set
            if (this.block?.properties?.panelStyle) {
                // Get panel style id
                const panelStyleId = this.getValue(this.block?.properties?.panelStyle)

                // Load panel styles
                return this.designer.currentStyle?.getPanelStylesById(panelStyleId) || []
                //return this.renderer.a2u.getPanelStylesById(panelStyleId) || []
            }

            // Return empty list
            return []
        },

        /**
         * Generate classes string
         * @return {string}
         */
        classesString() {
            return this.getClasses()
        },

        /**
         * Generate styles string
         * @return {string}
         */
        stylesString() {
            const resArr = {}
            const props = Object.assign({}, this.block.properties, this.dynamicPanelStyles);

            // Properties
            if (this.block.properties) {
                for (const [propName, propData] of Object.entries(props)) {
                    // Apply styles
                    if (propData !== false && propData !== undefined && propData !== 'none') switch (propName) {
                        /*case "fontSize":
                            resArr[`font-size`] = `${propData}px`
                            break;*/
                        case "controlTextColor":
                            resArr[`--control-text-color`] = `var(--foreground-color-${propData})`
                            break;
                        case "controlFocusColor":
                            resArr[`--control-focus-color`] = `var(--foreground-color-${propData})`
                            break;
                        case "controlUnfocusedColor":
                            resArr[`--control-unfocused-color`] = `var(--foreground-color-${propData})`
                            break;
                        case "lineHeight":
                            resArr[`line-height`] = propData
                            break;
                        case "textShadow":
                            resArr[`text-shadow`] = `${propData.left}px ${propData.top}px ${propData.color}`
                            break;
                        case "boxShadow":
                            resArr[`box-shadow`] = `${propData.x}px ${propData.y}px ${propData.blur}px ${propData.spread}px ${propData.color}`
                            break;
                        case "border":
                            resArr['border'] = `${propData.weight ? propData.weight : 0}px solid ${propData.color}`
                            break;
                        case "width":
                            resArr['width'] = propData
                            break;
                        case "height":
                            resArr['height'] = propData
                            break;
                        case "minWidth":
                            resArr['min-width'] = `${propData}px`
                            break;
                        case "minHeight":
                            resArr['min-height'] = propData
                            break;
                        case "maxWidth":
                            resArr['max-width'] = propData
                            break;
                        case "maxHeight":
                            resArr['max-height'] = propData
                            break;
                        case "background":

                            // Image background
                            if (propData && propData.type === 'image') {
                                resArr['background-image'] = `url(${this.getAssetValue(propData.image)})`
                                resArr['background-size'] = propData.backgroundSize;
                                resArr['background-position'] = propData.backgroundPosition;
                                resArr['background-repeat'] = 'no-repeat';
                            }

                            if (propData && propData.type === 'color') resArr['background-color'] = propData.color
                            if (propData && propData.type === 'gradient') resArr['background'] = `linear-gradient(${propData.radius}deg, ${propData.fromColor} 0%, ${propData.toColor} 100%)`
                            break;

                        case "flexWrap":
                            resArr['flex-wrap'] = propData
                            break;
                        case "textAlign":
                            resArr['text-align'] = propData
                            break;
                        case "textDecoration":
                            resArr['text-decoration'] = propData
                            break;
                        case "blur":
                            // Apply blur
                            resArr['filter'] = `blur(${constants.sizes_to_pixels[propData] || 0}px)`;
                            break;
                        case 'maskImage':
                            resArr['mask-image'] = `url(${this.getAssetValue(propData)})`;
                            resArr['mask-size'] = `100% 100%`;
                            resArr['mask-repeat'] = `no-repeat`;
                            break;
                    }
                }

                // Apply animation
                const animStyles = this.applyAnimation()
                Object.keys(animStyles).map(k => resArr[k] = animStyles[k]);
            }

            // Return string
            return resArr;
        }
    },


    methods: {

        /**
         * Get classes
         */
        getClasses(include = false, exclude = false) {
            const resArr = []
            const props = Object.assign({}, this.block.properties, this.dynamicPanelStyles);

            // Properties
            if (this.block.properties) for (const [propName, propData] of Object.entries(props)) {
                if (propData !== '') if(
                    (!exclude || !exclude[propName]) &&
                    (!include || include[propName])
                ) switch (propName) {
                        case "textStyle":
                            resArr.push(`dg-text-${propData}`)
                            break;
                        case "visibility":
                            if(propData !== undefined && !propData.showInEditor) resArr.push(`dg-invisible`)
                            break;
                        case "textColor":
                            resArr.push(`dg-foreground-${propData}`)
                            break;
                        case "backgroundColor":
                            resArr.push(`dg-background-${propData}`)
                            break;
                        case "padding":
                            propData?.left && resArr.push(`dg-pl-${propData.left}`);
                            propData?.top && resArr.push(`dg-pt-${propData.top}`);
                            propData?.right && resArr.push(`dg-pr-${propData.right}`);
                            propData?.bottom && resArr.push(`dg-pb-${propData.bottom}`);
                            break;
                        case "margin":
                            propData?.left && resArr.push(`dg-ml-${propData.left}`);
                            propData?.top && resArr.push(`dg-mt-${propData.top}`);
                            propData?.right && resArr.push(`dg-mr-${propData.right}`);
                            propData?.bottom && resArr.push(`dg-mb-${propData.bottom}`);
                            break;
                        case "gutter":
                            propData?.x && resArr.push(`dg-gutter-x-${propData.x}`);
                            propData?.y && resArr.push(`dg-gutter-y-${propData.y}`);
                            break;
                        case "extended":
                            if (propData) resArr.push(`dg-extended`)
                            break;
                        case "sticky":
                            if(propData) resArr.push(`dg-sticky-${this.block.properties?.stickyPosition || 'top'}`)
                            break;
                        case "ellipsis":
                            if(propData) resArr.push(`dg-ellipsis-${this.block.properties?.ellipsis || '1'}`)
                            break;
                        case "shadow":
                            resArr.push(`dg-shadow-${propData}`)
                            break;
                        case "size":
                            resArr.push(`dg-size-${propData}`)
                            break;
                        case "border":
                            if(propData?.radiusTopLeft) resArr.push(`dg-radius-tl-${propData.radiusTopLeft}`)
                            if(propData?.radiusTopRight) resArr.push(`dg-radius-tr-${propData.radiusTopRight}`)
                            if(propData?.radiusBottomLeft) resArr.push(`dg-radius-bl-${propData.radiusBottomLeft}`)
                            if(propData?.radiusBottomRight) resArr.push(`dg-radius-br-${propData.radiusBottomRight}`)
                            break;
                        case "textWeight":
                            resArr.push(`dg-text-weight-${propData}`)
                            break;
                        case "contentAlign":
                            resArr.push(`dg-justify-${propData}`)
                            break;
                        case "itemsAlign":
                            resArr.push(`dg-items-${propData}`)
                            break;
                        case "flexWrap":
                            resArr.push(`dg-wrap-${propData}`)
                            break;
                    }
            }

            // Return string
            return resArr.join(" ")
        },

        /**
         * Localizes a string based on the current locale.
         *
         * This function takes an object as a parameter and checks if it is localizable.
         *
         * @param {Object} params - The object to be localized. It should have the properties 'isLocalizable' and 'localeAlias'.
         * @return {Object} The localized object if localizations are available, otherwise the original object.
         */
        localizeString(params) {
            // Return params if params is not an object
            if (typeof params !== 'object') {
                return params;
            }

            // Return params if params is not localizable or does not have a locale alias
            if (!params?.isLocalizable || !params?.localeAlias) {
                return params;
            }

            // Get the current locale
            const currentLocale = this.app.currentMediaDesigner?.currentLocale
              || this.app.currentMediaDesigner?.mainLocale;

            // Return params if the current locale is the main locale
            if (currentLocale === this.app.currentMediaDesigner?.mainLocale) {
                return params;
            }

            // Get the localizations for the current locale
            const localizations = this.app.currentMediaDesigner?.localizations[currentLocale] || {};

            // Return params if there are no localizations for the current locale
            if (!localizations[params.localeAlias]) {
                return params;
            }

            // Return a new object with the value replaced by the localized value
            return {
                ...params,
                value: localizations[params.localeAlias] || params.value,
            };
        },

        /**
         * Interpret dynamic string
         */
        interpretString(params) {

            // Return string if string
            if(typeof params === 'string') return params;

            // Localize string
            const localizedParams = this.localizeString(params);

            // Get string
            let string = localizedParams?.value || localizedParams?.string;

            // Get variables from bindings and replace them in string
            for(const [key, value] of Object.entries(localizedParams?.bindings || {})) {
                // get value
                const val = this.getValue(value)
                string = string?.replace(`{${key}}`, val === undefined ? `{${key}}` : val)
            }

            // Return final string
            return string;
        },

        /**
         * Get asset value
         * @param asset
         * @return {*|boolean|{}}
         */
        getAssetValue(asset) {
            // Image path
            const imPath = this.getValue(asset);

            // Get path
            const path =  pathHelper.assetPath(typeof imPath === 'string' ? imPath :  imPath?.source_url)

            // Return path
            return path ? path : require("@/assets/plugs/default-image.png");
        },

        /**
         * Get value variable
         * @param variable
         */
        getValue(variable) {

            // Value
            let value = undefined;

            // Return value if value is string
            if(typeof variable !== 'object') return variable

            // Check if variable is static or expression
            if (['static', 'expression'].includes(variable?.valueType) && variable?.type === 'string') {
                // Update the variable value with the translated string
                if (variable.valueType === 'expression' && variable?.expression) {
                    variable.expression = this.localizeString(variable.expression);
                } else if (variable.valueType === 'static' && variable?.value) {
                    variable = this.localizeString(variable);
                }
            }

            // Return value if value is static
            if (variable?.valueType === 'static') {

                // Return values according to type
                switch (variable?.type) {
                    case "options":
                        value = this.globals.options?.collections?.[variable?.value?.type] || [];

                        // Localize options
                        if (variable?.isLocalizable) {
                            value = value.map((option) => ({
                                ...option,
                                title: this.localizeString({
                                    isLocalizable: true,
                                    localeAlias: `${this.module_id}.constants.${variable?.value?.type}.${option.value}`,
                                    value: option.title,
                                }) || option.title,
                            }));
                        }
                        break;

                    default:
                        value = variable?.value;
                }
            }

            // Parse storage if value is variable
            if (variable?.valueType === 'variable') {

                if(!variable.value) {
                    console.error('Empty value for ' + variable.name)
                }

                // Return code depending on source
                // Variable value has format "source:variable", unpack all params and trim spaces
                const [source, variablePath] = variable.value.split(':').map(v => v.trim())

                // Return var
                switch (source) {
                    case "constant":
                        //console.error(variablePath);
                        value = this.designer.constantStorage?.get?.(variablePath);
                        break;

                    case "app":
                        //console.error(variablePath);
                        value = this.designer.appStorage?.get?.(variablePath);
                        break;

                    case "fragment":
                        if(!this.parentFragment?.storage) console.error('Fragment storage not found for ' + variable.value)
                        value = this.parentFragment?.storage?.get?.(variablePath)
                        break;

                    /*case "diagram":
                        if(!this.parentWidget?.storage) console.error('Storage not found for ' + variable.value)
                        value = this.parentWidget?.storage?.get?.(variablePath)
                        break;*/

                    default:
                        if(!this.parentWidget?.storage) console.error('Storage not found for ' + variable.value)
                        value = this.parentWidget?.storage?.get?.(variablePath)
                        break;
                }

                // Check if value is undefined - try to get default value
                if(value === undefined) {
                    if(variable?.defaultValue !== undefined) value = variable?.defaultValue;
                }
            }

            // Check if value is localizable
            if (value?.isLocalizable) {
                // Return localized value
                return this.localizeString(value)?.value;
            }

            // Throw error if value is not found
            return value;
        },

        /**
         * Compare values
         * @param leftOp
         * @param rightOp
         * @param comparator
         * @return {boolean}
         */
        compareValues(leftOp, rightOp, comparator) {
            switch (comparator) {
                case "==":
                    return leftOp == rightOp;
                default:
                    throw 'Unknown comparator ' + comparator
            }
        },

        /**
         * Apply animation according to frame
         */
        applyAnimation() {

            // No animation
            if (!Array.isArray(this.block.properties.animation)) return []

            // Get animation
            const animations = this.block.properties.animation

            // Result styles array
            const transforms = [], styles = {}

// Find first frame
            let fromFrameIndex = -1, cnt = 0

// Find nearest frame
            for (const fr of animations) {
                if (fromFrameIndex >= 0 && this.animation_frame < fr.startTime) break
                if (this.animation_frame >= fr.startTime) fromFrameIndex = cnt
                cnt++
            }

// Get frames
            const fromFrame = animations[fromFrameIndex]
            const toFrame = animations[fromFrameIndex + 1]

// If frame found
            if (fromFrame) for (const attr of Object.keys(fromFrame)) {
                const fromVal = parseFloat(fromFrame[attr]),
                    toVal = parseFloat(fromVal && toFrame && toFrame[attr] !== undefined ? toFrame[attr] : fromVal),
                    interpolation = parseFloat(toFrame ? ((this.animation_frame - fromFrame.startTime) / (toFrame.startTime - fromFrame.startTime)) : 1),
                    value = fromVal + (toVal - fromVal) * interpolation

                // Skip undefined values
                if (fromVal === undefined || toVal === undefined || isNaN(fromVal) || isNaN(toVal)) continue

                //if(attr == 'opacity') console.log('anim', fromVal, value, toVal, this.animation_frame, fromFrame, toFrame)

                // Apply transformations
                switch (attr) {

                    // Regular props
                    case "width":
                        styles['width'] = `${value}%`
                        break;
                    case "height":
                        styles['height'] = `${value}%`
                        break;
                    case "opacity":
                        styles['opacity'] = `${value}%`
                        break


                    // Transforms
                    case "positionLeft":
                        transforms.push(`translateX(${value}px)`)
                        break
                    case "positionTop":
                        transforms.push(`translateY(${value}px)`)
                        break
                    case "rotation":
                        transforms.push(`rotate(${value}deg)`)
                        break
                    case "scale":
                        transforms.push(`scale(${value / 100})`)
                        break
                    default:
                        break
                }
            }

// Return list
            return Object.assign(styles, {'transform': transforms.join(" ")})
        },

    }

}
