<template>

  <q-form ref="funcForm" @submit="onSaveFunc" class="q-gutter-md">

    <q-input
        v-model="editFunction.package"
        label="Func package *"
        hint="package of func"
        lazy-rules
        :rules="[ val => val && val.length > 0 || 'Please type something']"
    />

    <q-input
        v-model="editFunction.name"
        label="Func name *"
        hint="name of func"
        lazy-rules
        :rules="[ val => val && val.length > 0 || 'Please type something']"
    />

    <q-select
      v-if="isDiagramFunction"
      v-model="editFunction.diagram_id"
      label="Diagram *"
      hint="func diagram"
      :options="diagramsOptions"
      emit-value
      map-options
      lazy-rules
      :rules="[ val => !!val || 'Please type something']"
    />

    <q-input
        type="textarea"
        v-model="editFunction.description"
        label="Function description *"
        lazy-rules
        :rules="[ val => val && val.length > 0 || 'Please type something']"
    />

    <q-card v-if="editFunction.id">
      <q-card-section class="bg-primary text-white">
        Function arguments
      </q-card-section>
      <q-card-section>
        <storage-tree
          :app-id="editFunc.app_id"
          :module-id="editFunc.module_id"
          :block_id="funcArgsStorageBlockId"
          :readonly="isDiagramFunction"
          :only-arguments="isDiagramFunction"
          mode="arguments"
        />
      </q-card-section>
    </q-card>

    <div class="row">
      <q-btn label="Save" type="submit" color="primary"/>
      <q-space/>
      <q-btn v-if="editFunction.id" label="Delete" color="warning" @click="deleteFunction"/>
    </div>
  </q-form>

</template>

<script>
import {nanoid} from "nanoid"
import {CodeFunction} from "@/../../common/db/CodeFunction.js"
import {Diagram} from '@/../../common/db/Diagram';
import StorageTree from "@/pages/workspace/storage/StorageTree.vue";

export default {
  name: "FunctionForm",
  components: {StorageTree},
  props: ['editFunc'],
  emits: ['saved', 'deleted'],
  data: () => ({
    product: false,
    editFunction: false
  }),

  computed: {
    /**
     * Computed property that checks if the current function is a diagram function.
     * @returns {Boolean} True if the function type is 'diagram', false otherwise.
     */
    isDiagramFunction() {
      return this.editFunction.type === 'diagram';
    },

    /**
     * Computed property that returns the ID of the diagram.
     * @returns {Number} The ID of the diagram if it exists, undefined otherwise.
     */
    diagramId() {
      return this.editFunction?.diagram_id;
    },

    /**
     * Return func args storage block id
     * @returns {string}
     */
    funcArgsStorageBlockId() {
      if (this.isDiagramFunction) {
        return `diagram-${this.diagramId}`;
      }

      return `func-args-${this.editFunc.id}`
    },

    /**
     * Computed property that returns an array of diagrams.
     * It waits for the diagrams to be fetched from the database using the app_id and diagram_type.
     *
     * @returns {Array} An array of diagrams if they exist, an empty array otherwise.
     */
    diagrams() {
      return this.wait("diagrams", async () => {
        return await Diagram.query().where({ module_id: this.editFunc.module_id, diagram_type: 'function' }).get()
      }, []);
    },

    /**
     * Computed property that returns an array of diagram options.
     * It filters the diagrams to only include those that have a corresponding module.
     * Each option is an object with a 'value' and a 'label' property.
     * The 'value' is the diagram id and the 'label' is a string containing the module name and the diagram title.
     *
     * @returns {Array} An array of diagram options.
     */
    diagramsOptions() {
      return this.diagrams.map(d => ({
        value: d.id,
        label: d.title,
      }))
    },
  },

  methods: {

    /**
     * Delete function
     * @return {Promise<void>}
     */
    async deleteFunction() {
      this.$q.dialog({
        title: 'Delete function',
        message: `Are you sure you want to delete function ${this.editFunction.name}?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {
        await CodeFunction.remote().delete(this.editFunction.id)
        this.$emit('deleted')
      })
    },

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveFunc() {

      // Add unique id if not exists
      if (!this.editFunction.unique_id) this.editFunction.unique_id = nanoid(10);

      // Update or created (depends on id field existence)
      const res = await CodeFunction.remote().save(this.editFunction)

      // Store new function if if not set
      this.editFunction.id = this.editFunction.id || res.id;

      // Hide dialog
      this.$emit('saved')
    }
  },

  async created() {
    this.editFunction = this.editFunc;

    // Subscribe to diagrams
    await Diagram.remote().subscribe("app-module-diagrams", {module_id: this.editFunc.module_id});
  },

  async beforeUnmount() {
    // Unsubscribe from diagrams
    await Diagram.remote().unsubscribe('app-module-diagrams');
  },
}

</script>
